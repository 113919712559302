<template>
    <div class="div_main">
		<el-divider content-position="left">频繁的问题的词条</el-divider>
			<table_template	:column_name="faq_column_name"	:base_url="httpUrl"		class_name="tb-third-party-wang-customer-service-faq"></table_template>
    </div>
</template>

<script>
	import table_template from '../../../template/wang/table_template_main_new.vue'
export default{
    components:{
        table_template
    },
    props: {
        
    },
    data() {
        return {
			httpUrl:this.$httpUrl,
            faq_column_name:[
				{label:"标题",prop:"title",map_type:'none',width:360},
				{label:"内容",prop:"describeTxt",map_type:'textarea'},
			]
        }
    },
    methods:{
        
    },
    computed:{
			
	},
    created(){
        
    },
}
</script>

<style  lang="less" scoped>
.div_main{
	background-color: white;
	border-radius: 10px;
	padding: 20px;
	.divider{	//分割器
		margin-top: 70px;		margin-bottom: 20px;
	}
}

</style>