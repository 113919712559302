import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'


// 添加全局样式
import './assets/css/global.css'
// iconfont
import './assets/css/iconfont.css'
// vuex
import store from './store'


Vue.prototype.$httpUrl = store.state.global_base_url.main;
// 公共js
import publicJs from "@/services/publicJs.js"

Vue.prototype.$publicJs = publicJs;
// loding最大等待时间
Vue.prototype.$lodingMaxTime = 10000;

// 引入axios
import axios from 'axios'
Vue.prototype.$http = axios
Vue.prototype.$baseURL = store.state.global_base_url.main;
axios.defaults.baseURL = store.state.global_base_url.main;
// 请求头
axios.interceptors.request.use(config=>{
  config.headers.Authorization = window.sessionStorage.getItem('token')
  return config
})

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
  store
}).$mount('#app')


// 路由发生变化修改页面title
router.beforeEach((to, from, next) => {
  if(to.meta.title){
    document.title = to.meta.title
  }
  next()
})