<template>
<div	class="main"		v-loading="loading"	element-loading-text="验证账号信息中...">
	<div class="centent">
		<div class="left">
			<img class="left_img" src="../assets/login/bg_left2.png" alt="" />
		</div>
		<div class="right">
			<img		class="right_img"		:src="rightImgSrc"		alt=""	@click="changeLoginImg()"/>
			<!-- <img class="right_img" src="../assets/other/5.png" alt="" /> -->
			<el-form	class="loginForm"		:model="loginMes"	:rules="rules"	ref="ruleForm"	label-width="100px">
				<el-form-item label="用户名" prop="account">
					<el-input	clearable		v-model="loginMes.account"		prefix-icon="iconfont icon-yonghu"		placeholder="请输入内容"		@keyup.enter.native="submitForm('ruleForm')"></el-input>
				</el-form-item>
				<el-form-item label="密码" prop="password">
					<el-input	show-password		clearable		v-model="loginMes.password"		prefix-icon="iconfont icon-yonghu"	placeholder="请输入内容"	@keyup.enter.native="submitForm('ruleForm')"></el-input>
				</el-form-item>
				<el-form-item class="loginForm_button">
					<el-button type="primary" @click="submitForm('ruleForm')">登录</el-button>
					<el-button @click="resetForm('ruleForm')">重置</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
	<!-- 这个是公信部要求的备案 -->
	<div	class="footer_beian">
		<!-- <img	src="@/assets/login/chinaIcon.png" class="chinaIcon"/> -->
		<a href="https://beian.miit.gov.cn/?wm=5317_0011sales/#/Integrated/index"  >{{beian.content}}</a>
	</div>
</div>
</template>

<script>
export default {
	name: "WorkspaceJsonLogin",
	data() {
		return {
			rightImgSrc: "",
			loginMes: {
				account: "",
				password: "",
			},
			rules: {
				account: [
					{ required: true, message: "请输入账号", trigger: "blur" },
					{ min: 6,	max: 20,	message: "长度在 6 到 20 个字符",	trigger: "blur",	},
				],
				password: [
					{ required: true, message: "请输入密码", trigger: "blur" },
					{	min: 6,	max: 20,	message: "长度在 6 到 20 个字符",	trigger: "blur",	},
				],
			},
			loading: false,
			beian:{	//这个是公信部要求的备案
				content:'ICP备案/许可证号：沪ICP备2023004243号 闽ICP备2020017008号-2 闽ICP备2020017008号-3  闽ICP备2020017008号-7  闽ICP备2020017008号-8'
			}
		};
	},
  created() {
    this.changeLoginImg();
    // 判断是否有token有的话判断登录是否过期
    let token = window.sessionStorage.getItem("token");
    if (null != token) {
      this.verifyLogin(token);
    }
    // 判断是否有默认填充的账号信息
    let loginDefaultMes = window.localStorage.getItem("loginDefaultMes");
    if (null != loginDefaultMes) {
      this.loginMes.account = loginDefaultMes;
    }
  },
  methods: {
    // 更换右侧小图片
    changeLoginImg() {
      // 获取1-10的随机数拼接登录图片路径
      let random = Math.ceil(Math.random() * 10);
      this.rightImgSrc = require("../assets/other/" + random + ".png");
      // this.$store.commit("changeThemeColor", "#00" + this.i);
    },
    submitForm(formName) {
      let that = this;
      // 开启加载中
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.loading = true;
          const { data: res } = await this.$http.post(
            "/login/login",
            this.loginMes
          );
          if (res.code == "200") {
            let mes = res.message;
            window.sessionStorage.setItem("userName", mes.name);
            window.sessionStorage.setItem("roleGrade", mes.roleGrade);
            window.sessionStorage.setItem("token", mes.token);
            // 默认登录账号
            window.localStorage.setItem(
              "loginDefaultMes",
              this.loginMes.account
            );
            this.$notify({
              title: "登录成功",
              type: "success",
            });
            this.$router.push({ path: "/home" });
          } else {
            this.$message.error(res.message);
          }
          // 关闭加载中
          this.loading = false;
        } else {
          return false;
        }
      });
      // 如果超过指定时间无响应，则关闭加载中
      setTimeout(function () {
        if (that.loading) {
          that.loading = false;
          that.$message.error("连接服务器超时");
        }
      }, that.$lodingMaxTime);
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 登录有效校验
    async verifyLogin(token) {
      let userName = window.sessionStorage.getItem("userName");
      let roleGrade = window.sessionStorage.getItem("roleGrade");
      if (
        this.isNotNull(userName) &&
        this.isNotNull(roleGrade) &&
        this.isNotNull(token)
      ) {
        let that = this;
        const { data: res } = await this.$http.get("login/tokenCheck");
        if (res.code != 200) {
          return that.$message.warning("身份信息过期，请重新登录");
        }

        let mes = res.message;
        window.sessionStorage.setItem("userName", mes.name);
        window.sessionStorage.setItem("roleGrade", mes.roleGrade);
        this.$router.push({ path: "/home" });
      }
    },
    isNotNull(e) {
      return e != null && e != undefined && e != "";
    },
  },
};
</script>

<style lang="less" scoped>
// .whole_container{
// 	display: flex;	flex-direction: column;		
// }
.main {
	widows: 100%;
	height: 100%;
	background-color: var(--themeColor);
	background-color: rgb(196, 231, 255);
	position: relative;
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	.centent {
		height: 60%;
		width: 60%;
		display: flex;
		.left {
			flex: 1;
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #fff;
			align-items: center;
			.left_img {
				width: 70%;
			}
		}
		.right {
			flex: 1;
			background-color: aliceblue;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			.right_img {
				width: 30%;
				// position: relative;
				margin-bottom: 5%;
			}
			.loginForm {
				width: 70%;
			}
			.loginForm_button {
				margin-top: 10%;
				.el-button:nth-child(1) {
					width: 50%;
				}
				.el-button:nth-child(2) {
					width: 30%;
				}
			}
		}
	}
	.footer_beian{
		position: fixed;
		bottom: 0;
		// width: 500px;
		height: 50px;
		text-align: center;
	}
}

</style>
