<template>
    <div>
<!-- 		<div	class="header"		>
			<slot		name="header"		class="header_item"></slot>
		</div> -->
		<!-- 整张表 -->
		<el-table	:data="table_data.show">
			<!-- 图片的地方 -->
			<el-table-column	label="图片展示" v-if="image.prop != null"	width="100">
				<template slot-scope="scope">	<img	class="table_image"  v-if="scope.row[image.prop]" :src="scope.row[image.prop]"/>	</template>
			</el-table-column>
			<!-- 正常的每个列表 -->
			<el-table-column v-for="(item,index) in column_name_filtered"  :key="index"
					:width="item.width" :label="item.label" :prop="item.prop">
				<!-- 防止某一列中的数据过长，导致某些行的高度偏高，在这里如果长度超过一定长度，会自动缩减到30个字符，然后全部内容会用一个小窗口显示 -->
				<template slot-scope="scope"	>
					<el-popover	v-if="(typeof scope.row[item.prop] == 'string') && scope.row[item.prop].length >30"	width="500"	trigger="hover"	:content="scope.row[item.prop]">
						<span slot="reference">{{scope.row[item.prop].slice(0,30)+'...'}}</span>
					</el-popover>
					<span	v-else>
						{{item.map_type=='base'||item.map_type=='boolean'?item.map[ scope.row[item.prop] ]:scope.row[item.prop]}}
					</span>
				</template>
			</el-table-column>
			<!-- 编辑和删除 -->
			<el-table-column  align="right" fixed="right"  :width="edit_del_button_width" >
				<template slot-scope="scope">	
					<slot name="right_button"	:row="scope.row" :index="scope.$index"	:change_custom_data="change_custom_data"></slot>
					<el-button  size="mini"  @click="show_dialog('edit', scope.row,scope.$index)">编辑</el-button>
					<el-button  size="mini"  type="danger"  @click="delete_row(scope.row)">删除</el-button>
					<el-button	size="mini"	 type="warning"	icon="el-icon-top"		v-if="sortIndexIncrease"	
						@click="sortIndexIncrease(scope.row.id);"	>
						向上
					</el-button>
					<el-button	size="mini"	 type="warning"	icon="el-icon-bottom"	v-if="sortIndexIncrease"
						@click="sortIndexIncrease(scope.row.id);">
						向下
					</el-button>
				</template>
			</el-table-column>
		</el-table>
		<!-- 下面的东西 -->
		<div class="foot">
			<el-button type="primary" @click="show_dialog('add')" size="small" class="button">新建一条记录</el-button>
			<el-pagination class="page"  background  layout="prev, pager, next" 	:current-page="table_data.current"	
					:page-size="table_data.size" :total="table_data.total"		@current-change="e=>{initialize_data(e)}"></el-pagination>
		</div>
		<slot	name="dialog"	:custom_data="custom_data"></slot>
		
		<!-- 弹窗 -->
		<el-dialog :title="dialog.show_content_type=='edit'?'修改记录':'新增记录'" :visible.sync="dialog.visible" width="800px" >
			<div class="form">
				<div class="row" v-for="(item,index) in add_edit_column_name_filtered"  :key="index" :class="item.map_type == 'textarea'?'bigger_row':''">
					<div class="label_container"><label>{{item.label}}</label></div>
					<div class="input_container">
						<!-- 普通映射类型 -->
						<el-select  v-if="item.map_type== 'base' " v-model="dialog.temp_data[item.prop]" placeholder="请选择">
							<el-option v-for="(item1,index1) in item.map" :label="item1" :value='index1'	:key="index1"></el-option> 
						</el-select>
						<!-- 下面这个是应对map的key值为boolean型 -->
						<el-select  v-else-if ="item.map_type== 'boolean' " v-model="dialog.temp_data[item.prop]" placeholder="请选择">
							<el-option  :label="item.map[false]" :value='false'></el-option> 
							<el-option  :label="item.map[true]" :value='true'></el-option> 
						</el-select>
						<el-upload v-else-if="item.map_type ==	'image' && dialog.show_content_type=='edit' "  class="avatar-uploader"  list-type="picture-card"   :show-file-list="false"
								:headers="{Authorization:token}"
								:action="base_url +class_name+'/'+ image_action_path"	:data="{id:dialog.temp_data.id}"  name="image"	:on-success="upload_success_handle">
							<img class="div_main_img" v-if="dialog.temp_data[item.prop]" :src="dialog.temp_data[item.prop]" />
							<i v-else class="el-icon-plus div_main_img"></i> 
						</el-upload>
						<!-- 普通的文字类型 -->
						<el-input v-else-if="item.map_type !=	'image' " :type="item.map_type == 'textarea'?'textarea':'text'"  v-model="dialog.temp_data[item.prop]" placeholder="暂时为空"></el-input>
					</div>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialog.visible = false">取 消</el-button>
				<el-button type="primary" @click="dialog_confirm">确 定</el-button>
			</span>
		</el-dialog>
		
	</div>
</template>

<script>
	import axios from 'axios'
	// import edit_add_dialog from './table_template_component/edit_add_dialog.vue'
export default{
    components:{
        // edit_add_dialog
    },
    props: {
		//发送请求什么的基本路径	例如：http://localhost:9000/
		base_url:{type:String,default:""},
		//第二段请求的路径 		例如 tb-third-party-wang-lucard-menu ，base_url+class_name+'/page'就是分页数据请求
		class_name:{type:String,default:''},
		// 这个是表的每一个字段名，还有每一个字段名所对应的信息，
		// 属性map_type：	 	map_type:"base"或者"boolean" 	 -> 基本映射和布尔映射，需要用map属性进行对应描述
		// 						map_type:"image"  				-> 图片属性
		// 						map_type:"none"					->就是正常的属性，是什么就显示什么
		//						map_type:"text"  				-> 就是显示的是文本，会比普通的字符串要来的长
		// 属性prop: 就是这个属性的英文名称，就是实际数据的key值
		// 属性width:就是这个属性的宽度
		// 属性label:就是这个属性的中文名称，就是给用户看的
		// 属性add_rule:就是校验这个属性 
		// 		例子： :add_rule="{  required: true, message: '域名不能为空', trigger: 'blur'	}"
		// 			  :add_rule="[
		//   				{ required: true, message: '请输入邮箱地址', trigger: 'blur' },
		//   				{ type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
		// 					]"
		column_name:{	type:Array,	default:()=>{return [] }	},
		//数据是否可以编辑，是否显示编辑按钮
		is_editable:{type:Boolean,default:true},
		//数据是否可以被删除，是否显示删除按钮
		is_can_be_delete:{type:Boolean,default:true},
		//在编辑、删除按钮的那边，是否延长额外的宽度，用来存放额外的按钮
		longer_width_for_button:{type:Number,defaut:0},
		//排序的属性（字段）名称,默认是降序，比如 3,2,1
		sort_prop:{type:String ,default:null},
		//排序是否是降序，和上面那个排序字段联合起来一起用,默认是降序
		sort_is_desc:{type:Boolean,default:true},
		//图片的上传后缀的地址，比如uploadImage
		image_action_path:{type:String,default:'uploadImage'},
		//这个是获取的函数
		page_function:{type:Function,default:null},
		//这个是更改的函数
		edit_function:{type:Function,default:null},
		//这个是添加的函数
		add_function:{type:Function,default:null},
		//这个是删除的函数
		delete_function:{type:Function,default:null},
		//这个是交换两条数据的排序的函数，
		//如果有，那么就会在编辑按钮栏那里显示 向上、向下 的按钮 
		// 该函数接受两条记录的id号，返回是否完成（Boolean）
		sortIndexIncrease:{type:Function,default:null},
		sortIndexDecrease:{type:Function,default:null},
		// 如果需要刷新数据，调用这个函数,这样可以在父组件控制刷新的时机
		// 有专门监听这个变量的函数，当这个变量变化时自动重新申请数据
		sign_for_refresh:{type:Boolean,default:true},
    },
    data() {
        return {
            image:{
				prop:null,	//展示图片的属性，该表中图片的属性名称，如果该表没有图片字段，就为null
			},
			table_data:{		//表的数据
				origin:[],
				show:[],
				current:1,
			},
			//这个是弹出的窗口的内容
			dialog:{
				visible:false,
				show_content_type:'add',
				temp_data:{}
			},
			token:sessionStorage.getItem("token"),		//token，有些请求会携带上
			custom_data:{}		//父组件可以自定义的数据，一般用于slot中的元素
        }
    },
    methods:{
		//初始化数据
		async	initialize_data(page_index = this.table_data.current){
			//赋值分页的信息。比如是第几页，总共有几页等等
			if(this.page_function == null){
				this.table_data =	await	this.get_origin_data_by_page(this.base_url,this.class_name,page_index);
			}else{
				if(this.page_function.length == 3){	//判断函数的参数有多少个
					this.table_data = 	await	this.page_function(this.base_url,this.class_name,page_index);
				}else if(this.page_function.length == 4){
					this.table_data = 	await	this.page_function(this.base_url,this.class_name,page_index,this.custom_data);
				}
			}
			this.table_data.origin = this.table_data.records ;
			this.table_data.show	= this.table_data_origin2table_data_show(JSON.parse(JSON.stringify(this.table_data.origin)),this.column_name);
		},
		//显示弹窗
		show_dialog(show_content_type,row={},index=0){
			this.dialog.visible =	true ;
			this.dialog.show_content_type = show_content_type ;
			if(show_content_type == 'add'){	//如果是添加窗口
				this.dialog.temp_data = {}	;
			}
			if(show_content_type == 'edit'){	//如果是编辑窗口
				this.dialog.temp_data = this.table_data.origin[index];
			}
		},
		//删除操作
		delete_row(row){
			if(this.delete_function == null){
				this.delete_row_by_id(row);
			}else{
				this.delete_function(this.base_url,this.class_name,row);
			}
			setTimeout(()=>{ this.initialize_data();},700);
		},
		// 编辑操作
		edit_row(row){
			if(this.edit_function == null){
				this.default_edit_row(row);
			}else{
				this.edit_function(this.base_url,this.class_name,row);
			}
			setTimeout(()=>{ this.initialize_data();},700);
			this.dialog.visible	=	false ;
		},
		//	添加操作
		add_row(row){
			if(this.add_function == null){
				this.default_add_row(row);
			}else{
				this.add_function(this.base_url,this.class_name,row)
			}
			setTimeout(()=>{ this.initialize_data();},700);
			this.dialog.visible	=	false ;
		},
		// 弹窗中的确认按钮
		dialog_confirm(){
			if(this.dialog.show_content_type == 'edit'){
				this.edit_row(this.dialog.temp_data);
			}else{
				this.add_row(this.dialog.temp_data);
			}
		},
		//上传成功后做的事情
		upload_success_handle(res){
			console.log(res.code);
			if(res.code == 200){
				this.dialog.temp_data[this.image.prop] =res.message;
			}else{
				this.$message.error("上传图片失败");
			}
		},
		//用来改变自定义数据，一般用于slot中的东西
		change_custom_data(data){
			this.custom_data = {...this.custom_data,...data};
		},
//下面是辅助函数，就是被别的函数所调用的那种----------------------------------------------
		//获取原始数据
		async	get_origin_data_by_page(base_url,class_name,page_index = this.table_data.current){
			let	url = base_url+class_name + '/page?current=' + page_index ;
			const result = await	axios.get(url)
			if(result.status != 200)				
			{	this.$message.error("网络错误，无法加载内容");	}
			if(result.data.code != 200)	
			{	this.$message.error(result.data.message);	}
			return result.data.message ;
		},
		//处理原始数据,返回在列表中需要展示的数据
		table_data_origin2table_data_show(data_origin,column_name){
			let output = data_origin ;
			output.map(row=>{	//处理每一行
				column_name.forEach(colu=>{	//处理每一行中的每一列
					if(colu.map_type == "image")
					{	this.image.prop = colu.prop	}
				})
				return row;
			})
			return output;
		},
		// 添加某行
		async	default_add_row(row){
			const	result	=	await	axios.post(this.base_url+this.class_name+'/add',row)	;
			if(result.data.code	== 200) {
				this.$message({ message: '添加成功',type: 'success', center: true});
			}else{
				this.$message({ message: '添加失败',type: 'error', center: true});
			}
		},
		//	编辑某行
		async	default_edit_row(row){
			const	result =	await	axios.put(this.base_url+this.class_name+'/update',row);
			console.log(result)
			if(result.data.code == 200){
				this.$message({	message: '更改成功',type: 'success', center: true});
			}else{
				this.$message({	message: '更改失败',type: 'error', center: true	});
			}
		},
		//删除某一行的数据,用它的id号 
		delete_row_by_id(row){
			let axiosUrl = this.base_url+this.class_name+'/del/' + row.id ;
			axios.delete(axiosUrl)
			.then(response=>{
				if(response.data.code == 200){
					this.$message({ message: '删除成功',type: 'success', center: true});
				}else{
					this.$message({ message: '删除失败',type: 'error', center: true});
				}
			})
		},
//辅助函数结束----------------------------------------------
    },
    computed:{
		//右侧所有按钮加起来的宽度
		edit_del_button_width(){
				let origin_width = 150 ;
				if(! this.is_editable)						{	origin_width -=75 ;	}
				if(! this.is_can_be_delete)					{	origin_width -=75 ;	}
				if(  this.sortIndexIncrease) 				{	origin_width +=150; }
				origin_width += this.longer_width_for_button;
				return origin_width;
		},
		column_name_filtered(){
			return this.column_name.filter(item => {
				if(item.map_type == 'image')	return false;
				return true
			})
		},
		add_edit_column_name_filtered(){
			return this.column_name.filter(item => {
				// if(item.map_type == 'image')	return false;	
				return true
			})
		}
	},
	watch: {
		// 当该变量更改的时候，进行刷新的操作
		sign_for_refresh:function(){
			this.initialize_data(); //刷新数据
		}
	},
    created(){
        this.initialize_data();
    },
}
</script>

<style  lang="less" scoped>
	.header{
		.header_item{
			height: 8%;
		}
		width: 100%;
	}
	
	.table_image{
		height: 50px;	width: 50px;
	}
	
	.foot{
		width: 100%;
		height: 8%;
		margin-top: 20px;
		display: flex;
		justify-content: space-between;
		align-items: center; 
		.button{
			margin-left: 10px;
		}
	}
	
	.form{
		display: flex;
		flex-wrap: wrap;
	}
	.form .row{
		display: flex;
		width: 350px;
		.label_container{
			display: flex;
			justify-content: right;
			align-items: center;
			width: 100px;
			height: 70px; 
			color: #888888;
		}
		.input_container{
			display: flex;
			justify-content: left;
			align-items: center;
			margin-left: 20px ;
			flex:1;
			height: 70px;
			// 图片的
			.avatar-uploader{
				width: 50px;
				height: 50px;
				.div_main_img{
					width: 80px;
					height: 80px;
				}
			}
		}
	}
	.bigger_row{
		width:700px	!important;
	}
</style>