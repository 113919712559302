<template>
    <div	class="navigator_container">
		<div	class="block"	v-for="(item,index) in sort_data_list"		:key="index"		
			:class="current_index == index ? 'block_choosed':''"		@click="choose_sort(index)">
			<div	class="title">
				<el-input 	v-model="edit.temp_txt"	autofocus	:clearable="true"	v-if="edit.index == index "	
						size="mini"	@blur="edit_complete(index)"></el-input>
				<div		v-else>{{item.name}}</div>
				<div	class="appCount">{{ item.appCount}}</div>
			</div>
		</div>
    </div>
</template>

<script>
import axios from 'axios';
export default{
    components:{
        
    },
    props: {
        sort_data_list:{type:Array},//所有的类别
		current_index:{type:Number},//当前左边导航栏选中的类别的下标 index
    },
    data() {
        return {
			edit:{
				temp_txt:"",
				index:-1,
			},
			add:{
				temp_txt:"",
			}
			
        }
    },
    methods:{
        choose_sort(index){		//选中某一类
			this.$emit("choose_sort",index); 
		},
		// 下面是删除某一个类---------------------------------
		// async	delete_class(index){
		// 	try{
		// 		const result = await	this.$confirm('您确认要删除这个类嘛:   '+this.sort_data_list[index].name+'  ？', '确认信息', {
		// 			distinguishCancelAndClose: true,
		// 			confirmButtonText: '确定',
		// 			cancelButtonText: '放弃'
		// 		});
		// 		if(result == 'confirm'){	//如果是确定
		// 			const {data:{message:web_result}}= await axios({
		// 				url:this.$httpUrl+"tb-third-party-wang-batch-class/del",	
		// 				method:"delete",
		// 				data:{
		// 					id:this.sort_data_list[index].id,
		// 				}
		// 			})
		// 			if(web_result == true ){
		// 				this.$message.success("成功更改");
		// 			}else{
		// 				this.$message.error("更改失败");
		// 			}
		// 		}
		// 	}catch(e){		//取消就什么都不做
				
		// 	}
		// 	this.$emit("refresh");	
		// },
		//下面是编辑的部分,现在这个也废弃了--------------------------------
		// edit_name(index,event){
		// 	event.stopPropagation()
		// 	this.edit.index = index ;
		// 	this.edit.temp_txt  = this.sort_data_list[index].name ;
		// },
		//确认更改	
		// async	edit_complete(index){
		// 	this.edit.index = -1;
		// 	try{
		// 		const result = await	this.$confirm('您是否要使用这个名字:   '+this.edit.temp_txt+'  ？', '确认信息', {
		// 			distinguishCancelAndClose: true,
		// 			confirmButtonText: '保存',
		// 			cancelButtonText: '放弃修改'
		// 		})
		// 		if(result == 'confirm'){	//如果是确定
		// 			const {data:{message:web_result}}= await axios({
		// 				url:this.$httpUrl+"tb-third-party-wang-batch-class/update",	
		// 				method:"put",
		// 				data:{
		// 					id:this.sort_data_list[index].id,
		// 					name:this.edit.temp_txt
		// 				}
		// 			})
		// 			if(web_result == true ){
		// 				this.$message.success("成功更改");
		// 			}else{
		// 				this.$message.error("更改失败");
		// 			}
		// 		}
		// 	}catch(e){		//放弃修改，什么都不做
				
		// 	}
		// 	this.$emit("refresh");		//让页面刷新一下
		// },
		// 下面是添加一个类,现在已经废弃了---------------------------
		// async	to_add_class(){
		// 	try{
		// 		const	result = await	this.$prompt('请输入类别的名称', '提示', {
		// 			confirmButtonText: '确定',
		// 			cancelButtonText: '取消',
		// 		})
		// 		if(result.action == 'confirm'){
		// 			const {data:{message:web_result}}= await axios({
		// 				url:this.$httpUrl+"tb-third-party-wang-batch-class/add",	
		// 				method:"post",
		// 				data:{
		// 					name:result.value
		// 				}
		// 			})
		// 			if(web_result == true ){
		// 				this.$message.success("添加成功");
		// 			}else{
		// 				this.$message.error("添加失败");
		// 			}
		// 		}
		// 	}catch(e){	//如果没有取消，不做操作
				
		// 	}
		// 	this.$emit("refresh");	
		// }
    },
    computed:{
		
	},
    created(){
        
    },
}
</script>

<style  lang="less" scoped>
.navigator_container{
	display: flex;	flex-wrap: wrap;
	padding-bottom:2px;
	.block{
		min-width: 180px;	height: 40px;		
		border-radius: 4px;
		margin-top: 10px;	margin-right: 18px;
		display: flex;	flex-direction: column;	
		position: relative;
		cursor: pointer; 
		background-color: #eee;
		border:1px solid #eee;
		transition: background-color 0.5s;
		.title{
			margin-top: 10px;		margin-left: 10px;
			width: 160px;		height: 20px;
			color: #444;
			display: flex;	justify-content: space-between;
			.appCount{
				margin-left: 10px;
			}
		}
		.icon{
			cursor: pointer;
			position: absolute;
			top: 8px;	
			color: white;
			z-index: 200;
			width: 25px;		height: 25px;	border-radius: 5px;
			display: flex;	justify-content: center;		align-items: center;
		}
		.edit_icon{
			right: 10px;
			background-color: #67C23A;
		}
		// .delete_icon{
		// 	right: 10px;
		// 	background-color: #F56C6C;
		// }
		.desc{
			
		}
	}
	.block:hover{
		background-color: antiquewhite;
	}
	.block_choosed{
		background-color: #409EFF	!important;	
		color: white;
		.title{
			color: white;
		}
	}
	.add_class{
		border:2px dashed #999;
		width: 100px;
		background-color: #F2F6FC;
		display: flex;	justify-content: center;	align-items: center;
		font-size: 20px;		color: #999;
	}
	

}
	
	/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
	::-webkit-scrollbar {
		width: 0.75rem;
		height: 0.75rem;
		background-color: #F5F5F5;
	}
	
	/*定义滚动条轨道 内阴影+圆角*/
	::-webkit-scrollbar-track {
		// box-shadow: inset 0 0 0.375rem #999;
		border-radius: 0.625rem;
		background-color: #F5F5F5;
	}
	
	/*定义滑块 内阴影+圆角*/
	::-webkit-scrollbar-thumb {
		border-radius: 0.625rem;
		box-shadow: inset 0 0 0.375rem #ccc;
		background-color: #aaa;
	}
</style>