<template>
    <div>
		<el-button type="primary" @click="add">添加</el-button>
		<el-button type="primary" @click="clear">清空</el-button>
		<el-table	:data="list">
				<el-table-column  label="标题"  width="250">
						<template slot-scope="scope">
								<el-input v-model="scope.row.title" placeholder="标题"	clearable></el-input>
						</template>
				</el-table-column>
				<el-table-column  label="详细内容" >
						<template slot-scope="scope">
								<el-input v-model="scope.row.describe" type="textarea"  :rows="2"  placeholder="详细"	clearable></el-input>
						</template>
				</el-table-column>
				<el-table-column  label="排序"  width="150">
						<template slot-scope="scope"	>
								<el-button type="primary" @click="row_up(scope.$index)"	:disabled="scope.$index==0"	size="mini">向上</el-button>
								<el-button type="primary" @click="row_down(scope.$index)" :disabled="scope.$index==list.length-1"	size="mini">向下</el-button>
						</template>
				</el-table-column>
				<el-table-column  label="操作"  width="80">
						<template slot-scope="scope"	>
								<el-button type="primary" @click="delete_row(scope.$index)" size="mini">删除</el-button>
						</template>
				</el-table-column>
		</el-table>
	</div>
</template>

<script>
export default{
    props: {
		input_list:{	type:Array}
    },
    data() {
        return {
            list:this.input_list
        }
    },
    methods:{
        add(){
			this.list.push({sort:this.sort_default})
		},
		row_up(index){
			let temp = this.list[index] ;
			this.$set(this.list,index,this.list[index-1]);
			this.$set(this.list,index-1,temp);
		},
		row_down(index){
			let temp = this.list[index] ;
			this.$set(this.list,index,this.list[index+1]);
			this.$set(this.list,index+1,temp);
		},
		delete_row(index){
			this.list.splice(index,1);
		},
		clear(){
			this.list =[];
		}
    },
    computed:{
			//求出新的一行的排序值
			sort_default(){
					if(this.list.length == 0){
						return 0;
					}else{
						let max = this.list[0].sort ;
						this.list.forEach(	(item)=>{		if(item.sort>max)	max=item.sort;	}	)
						return max+1;
					}
			},
	},
    created(){
        
    },
	watch:{
		list:{
				deep:true,
				handler(newValue){
					this.$emit("update:input_list",newValue);
				}
		}
	}
}
</script>

<style  lang="less" scoped>
.flex_colu{
	display: flex;		flex-direction: column;		align-items: center;
 }
</style>