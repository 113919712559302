<template>
    <div>
		<el-button	class="button"	type="primary"		@click="edit_name">编辑名字</el-button>
    </div>
</template>

<script>
	import axios from 'axios';
export default{
    components:{
        
    },
    props: {
        batch_item:{}
    },
    data() {
        return {
            name:this.batch_item.name
        }
    },
    methods:{
		async	edit_name(){
			try{
					console.log(this.batch_item)
					const	result = await	this.$prompt('请输入类别的名称', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						inputValue:this.batch_item.name,
					})
					if(result.action == 'confirm'){
						const {data:{message:web_result}}= await axios({
							url:this.$httpUrl+"tb-third-party-wang-batch-class/update",	
							method:"put",
							data:{
								id:this.batch_item.id,
								name:result.value
							}
						})
						if(web_result == true ){
							this.$message.success("添加成功");
						}else{
							this.$message.error("添加失败");
						}
					}
			}catch(e){		//放弃修改，什么都不做
				
			}
			this.$emit("refresh");		//让页面刷新一下
		}
    },
    computed:{
			
	},
    created(){
        
    },
}
</script>

<style  lang="less" scoped>
.button{
	margin-right: 20px;
}
</style>