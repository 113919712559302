<template>
  <div class="div_home">
    <!-- 主体样式 -->
    <el-card>
      <div class="div_home_title">
        <el-row :gutter="25">
          <!-- 搜索区域 -->
          <!-- 搜索添加 -->
          <el-col :span="10">
            <el-input
              placeholder="请输入搜索内容"
              v-model="queryInfo.query"
              clearable
              @clear="getNewList"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getNewList"
              ></el-button>
            </el-input>
          </el-col>
          <!-- 搜索按钮 -->
          <el-col :span="4">
            <el-button type="primary" @click="addDialogVisible = true"
              >添加小程序
            </el-button>
          </el-col>
        </el-row>
      </div>
      <!-- 列表 border:边框  stripe隔行变色-->
      <el-table :data="tableList" border stripe>
        <el-table-column type="index" fixed></el-table-column>
        <!-- 索引列 -->
        <el-table-column
          label="名称"
          prop="name"
          width="200"
          fixed
        ></el-table-column>
        <el-table-column
          label="唯一标识"
          prop="code"
          width="200"
        ></el-table-column>
        <el-table-column
          label="appid"
          prop="appId"
          width="200"
        ></el-table-column>
        <!-- <el-table-column
          label="创建人"
          prop="createBy"
          width="200"
        ></el-table-column> -->
        <el-table-column
          label="创建时间"
          prop="createTime"
          width="200"
        ></el-table-column>
        <!-- <el-table-column
          label="所属公司"
          prop="companyName"
          v-if="'1' == roleGrade"
          width="200"
        ></el-table-column> -->
        <el-table-column
          label="跳转h5"
          prop="jumpToH5StatusText"
        ></el-table-column>
        <el-table-column label="状态" prop="status" width="80" fixed="right">
          <!-- 作用域插槽 -->
          <template slot-scope="scope">
            <!-- {{scope.row}}每一行封装的数据 -->
            <el-switch
              v-model="scope.row.status"
              @change="stateChange(scope.row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="营销" prop="status" width="200" fixed="right">
          <template slot-scope="scope">
            <!-- 消息推送 -->
            <el-tooltip
              effect="dark"
              content="消息模板"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="warning"
                icon="el-icon-chat-dot-square"
                size="mini"
                @click="messageTemplate(scope.row)"
              ></el-button>
            </el-tooltip>
            <!-- 关注生活号 -->
            <el-tooltip
              effect="dark"
              content="关注生活号"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="warning"
                icon="el-icon-s-check"
                size="mini"
                @click="lifeRelationVisibleShow(scope.row.id)"
              ></el-button>
            </el-tooltip>
            <!-- 服务管理 -->
            <el-tooltip
              effect="dark"
              content="服务管理"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="warning"
                icon="el-icon-guide"
                size="mini"
                @click="serviceManagement(scope.row)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="300">
          <template slot-scope="scope">
            <!-- 修改 -->
            <el-tooltip
              effect="dark"
              content="编辑"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="showEditDialog(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              effect="dark"
              content="小程序配置管理"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="warning"
                icon="el-icon-setting"
                size="mini"
                @click="showConfiguration(scope.row.code)"
              ></el-button>
            </el-tooltip>
            <!-- 页面数据编辑 -->
            <!-- 文字提示 enterable隐藏 -->
            <el-tooltip
              effect="dark"
              content="小程序展示数据编辑"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="warning"
                icon="el-icon-table-lamp"
                size="mini"
                @click="dataModification(scope.row.code)"
              ></el-button>
            </el-tooltip>
            <!-- 复制小程序排版 -->
            <el-tooltip
              effect="dark"
              content="复制小程序排版"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="success"
                icon="el-icon-document-copy"
                size="mini"
                @click="showLayoutCoty(scope.row.code)"
              >
              </el-button>
            </el-tooltip>
            <!-- 删除 -->
            <el-tooltip
              effect="dark"
              content="删除"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="deleteThis(scope.row.id)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- element分页组件 size-change每页最大变化数 current-change当前最大变化 layout功能组件-->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-sizes="[5, 10, 20, 100]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>
    <!-- 新增窗口 -->
    <el-dialog
      title="新增"
      :visible.sync="addDialogVisible"
      width="50%"
      @close="addDialogClose"
    >
      <el-form
        :model="addForm"
        :rules="formRules"
        ref="addFormRed"
        label-width="70px"
      >
        <!-- 名称 -->
        <el-form-item label="名称" prop="name">
          <el-input
            v-model="addForm.name"
            placeholder="请输入名称"
            maxlength="100"
            show-word-limit
            clearable
          ></el-input>
        </el-form-item>
        <!-- 唯一标识 -->
        <el-form-item label="标识" prop="code">
          <el-input
            v-model="addForm.code"
            clearable
            maxlength="100"
            show-word-limit
            placeholder="请输入唯一标识(不可更改，不能含有中文)"
            @input="inputCheck(addForm)"
          ></el-input>
        </el-form-item>
        <!-- appId -->
        <el-form-item label="appid" prop="appId">
          <el-input
            v-model="addForm.appId"
            clearable
            maxlength="100"
            placeholder="请输入appid(不可更改)"
          ></el-input>
        </el-form-item>
        <!-- 应用私钥 -->
        <el-form-item label="应用私钥" prop="privateKey">
          <el-input
            type="textarea"
            :rows="4"
            v-model="addForm.privateKey"
            clearable
            maxlength="20000"
            placeholder="请输入应用私钥"
          ></el-input>
        </el-form-item>
        <!-- 公钥 -->
        <el-form-item label="应用公钥" prop="publicKey">
          <el-input
            type="textarea"
            :rows="4"
            v-model="addForm.publicKey"
            clearable
            maxlength="20000"
            placeholder="请输入应用公钥"
          ></el-input>
        </el-form-item>
        <!-- 支付宝公钥 -->
        <el-form-item label="支付宝公钥" prop="alipayPublicKey">
          <el-input
            type="textarea"
            :rows="4"
            v-model="addForm.alipayPublicKey"
            clearable
            maxlength="20000"
            placeholder="请输入支付宝公钥"
          ></el-input>
        </el-form-item>
        <!-- 周边类型 -->
        <el-form-item label="周边类型" prop="poiType">
          <el-select
            v-model="addForm.poiType[0]"
            filterable
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in poiList"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="addForm.poiType[1]"
            filterable
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in poiList"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="addForm.poiType[2]"
            filterable
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in poiList"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 轮播图 -->
        <el-form-item label="轮播图" prop="rotationMap">
          <template>
            <el-switch v-model="addForm.rotationMap"> </el-switch>
          </template>
        </el-form-item>
        <!-- 菜单列表 -->
        <el-form-item label="菜单列表" prop="menuList">
          <template>
            <el-switch v-model="addForm.menuList"> </el-switch>
          </template>
        </el-form-item>
        <!-- 图文列表 -->
        <el-form-item label="图文列表" prop="imageText">
          <template>
            <el-switch v-model="addForm.imageText"> </el-switch>
          </template>
        </el-form-item>
        <!-- 用户授权 -->
        <el-form-item label="用户授权" prop="authorization">
          <template>
            <el-switch v-model="addForm.authorization"> </el-switch>
          </template>
        </el-form-item>
        <!-- 模版消息 -->
        <el-form-item label="模版消息" prop="message">
          <template>
            <el-switch v-model="addForm.message"> </el-switch>
          </template>
        </el-form-item>
        <!-- 状态 -->
        <el-form-item label="状态" prop="message">
          <el-switch v-model="addForm.status"> </el-switch>
        </el-form-item>
      </el-form>
      <!-- 底部按钮 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="toAdd">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑窗口 -->
    <el-dialog title="修改" :visible.sync="editDialogVisible" width="50%">
      <el-form
        :model="editForm"
        :rules="formRules"
        ref="editFormRed"
        label-width="70px"
      >
        <!-- 名称 -->
        <el-form-item label="名称" prop="name">
          <el-input
            v-model="editForm.name"
            placeholder="请输入名称"
            maxlength="100"
            show-word-limit
            clearable
          ></el-input>
        </el-form-item>
        <!-- 唯一标识 -->
        <el-form-item label="标识" prop="code">
          <el-input
            v-model="editForm.code"
            disabled
            clearable
            maxlength="100"
            show-word-limit
            placeholder="请输入唯一标识"
            @input="inputCheck(editForm)"
          ></el-input>
        </el-form-item>
        <!-- appId -->
        <el-form-item label="appid" prop="appId">
          <el-input
            v-model="editForm.appId"
            disabled
            clearable
            maxlength="100"
            show-word-limit
            placeholder="请输入appid"
          ></el-input>
        </el-form-item>
        <!-- 应用私钥 -->
        <el-form-item label="应用私钥" prop="privateKey">
          <el-input
            type="textarea"
            :rows="4"
            v-model="editForm.privateKey"
            clearable
            maxlength="20000"
            placeholder="请输入应用私钥"
          ></el-input>
        </el-form-item>
        <!-- 应用公钥 -->
        <el-form-item label="应用公钥" prop="publicKey">
          <el-input
            type="textarea"
            :rows="4"
            v-model="editForm.publicKey"
            clearable
            maxlength="20000"
            placeholder="请输入应用公钥"
          ></el-input>
        </el-form-item>
        <!-- 支付宝公钥 -->
        <el-form-item label="支付宝公钥" prop="alipayPublicKey">
          <el-input
            type="textarea"
            :rows="4"
            v-model="editForm.alipayPublicKey"
            clearable
            maxlength="20000"
            placeholder="请输入支付宝公钥"
          ></el-input>
        </el-form-item>
        <!-- 周边类型 -->
        <el-form-item label="周边类型" prop="poiType">
          <el-select
            v-model="editForm.poiType[0]"
            filterable
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in poiList"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="editForm.poiType[1]"
            filterable
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in poiList"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="editForm.poiType[2]"
            filterable
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in poiList"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 轮播图 -->
        <el-form-item label="轮播图" prop="rotationMap">
          <template>
            <el-switch v-model="editForm.rotationMap"> </el-switch>
          </template>
        </el-form-item>
        <!-- 菜单列表 -->
        <el-form-item label="菜单列表" prop="menuList">
          <template>
            <el-switch v-model="editForm.menuList"> </el-switch>
          </template>
        </el-form-item>
        <!-- 图文列表 -->
        <el-form-item label="图文列表" prop="imageText">
          <template>
            <el-switch v-model="editForm.imageText"> </el-switch>
          </template>
        </el-form-item>
        <!-- 用户授权 -->
        <el-form-item label="用户授权" prop="authorization">
          <template>
            <el-switch v-model="editForm.authorization"> </el-switch>
          </template>
        </el-form-item>
        <!-- 模版消息 -->
        <el-form-item label="模版消息" prop="message">
          <template>
            <el-switch v-model="editForm.message"> </el-switch>
          </template>
        </el-form-item>
        <!-- 状态 -->
        <el-form-item label="状态" prop="message">
          <el-switch v-model="editForm.status"> </el-switch>
        </el-form-item>
      </el-form>
      <!-- 底部按钮 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editMes">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 小程序页面展示数据编辑 -->
    <el-dialog
      title="小程序页面展示数据编辑"
      :visible.sync="dataModificationVisible"
      width="70%"
      @close="dataModificationClose"
      v-loading="dataModificationLoading"
    >
      <el-form ref="dataModificationRed" label-width="70px">
        <el-divider content-position="left">轮播图</el-divider>
        <div class="small_update_list">
          <!-- 新增 -->
          <div class="small_update_single">
            <div class="small_update_single_img_div">
              <el-upload
                class="avatar-uploader"
                :action="httpUrl + 'upload/uploadFile'"
                :show-file-list="false"
                :on-success="
                  (value) => {
                    handleAvatarSuccess(value, newRotationImg);
                  }
                "
                :on-change="onChangeUpload"
                :before-upload="beforeAvatarUpload"
                :headers="headerObj"
                :data="{
                  type: 'rotationImg',
                  code: dataModificationCode,
                }"
              >
                <img
                  class="small_update_single_img avatar"
                  v-if="newRotationImg.img"
                  :src="newRotationImg.img"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
            <div class="small_update_single_mes">
              <div class="small_update_single_mes_input">
                <el-input
                  v-model="newRotationImg.src"
                  placeholder="请输入跳转路径"
                ></el-input>
              </div>
              <div class="small_update_single_mes_input">
                <el-input
                  v-model="newRotationImg.mes"
                  placeholder="请输入名称"
                  maxlength="10"
                  show-word-limit
                ></el-input>
              </div>
              <div class="small_update_single_mes_button">
                <el-button
                  type="primary"
                  style="width: 100%"
                  @click="toAddRotationImg('rotationImg')"
                  >新增</el-button
                >
              </div>
            </div>
          </div>
          <!-- 已存在的列表 -->
          <div
            class="small_update_single"
            v-for="(item, index) in dataModificationMes.rotationImgList"
            :key="item.id"
          >
            <div class="small_update_single_img_div">
              <el-upload
                class="avatar-uploader"
                :action="httpUrl + 'upload/uploadFile'"
                :show-file-list="false"
                :on-success="
                  (value) => {
                    handleAvatarSuccess(value, item);
                  }
                "
                :on-change="onChangeUpload"
                :before-upload="beforeAvatarUpload"
                :data="{
                  id: item.id,
                  type: 'rotationImg',
                  code: dataModificationCode,
                }"
                :headers="headerObj"
              >
                <img
                  class="small_update_single_img avatar"
                  v-if="item.img"
                  :src="item.img"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
            <div class="small_update_single_mes">
              <div class="small_update_single_mes_input">
                <el-input
                  v-model="item.src"
                  placeholder="请输入跳转路径"
                  @focus="smallUpdateInputFoncus('rotationImg', 'src', item)"
                  @blur="smallUpdateInputBlur(item)"
                ></el-input>
              </div>

              <div class="small_update_single_mes_input">
                <el-input
                  v-model="item.mes"
                  placeholder="请输入名称"
                  maxlength="10"
                  show-word-limit
                  @focus="smallUpdateInputFoncus('rotationImg', 'mes', item)"
                  @blur="smallUpdateInputBlur(item)"
                ></el-input>
              </div>
              <div class="small_update_single_mes_button">
                <el-button
                  icon="el-icon-arrow-left"
                  :disabled="index == 0"
                  @click="
                    smallUpdateMoveLocation(
                      dataModificationMes.rotationImgList,
                      index,
                      -1
                    )
                  "
                ></el-button>
                <!-- 删除 -->
                <el-tooltip
                  effect="dark"
                  content="删除"
                  placement="top-start"
                  :enterable="false"
                >
                  <el-button
                    type="danger"
                    icon="el-icon-delete"
                    @click="
                      deleteThisLabel(
                        dataModificationMes.rotationImgList,
                        index,
                        item.id
                      )
                    "
                    >删除</el-button
                  >
                </el-tooltip>
                <el-button
                  icon="el-icon-arrow-right"
                  :disabled="
                    index == dataModificationMes.rotationImgList.length - 1
                  "
                  @click="
                    smallUpdateMoveLocation(
                      dataModificationMes.rotationImgList,
                      index,
                      1
                    )
                  "
                ></el-button>
              </div>
            </div>
          </div>
        </div>
        <el-divider content-position="left">菜单列表</el-divider>
        <div class="small_update_list">
          <!-- 新增 -->
          <div class="small_update_single">
            <div class="small_update_single_img_div">
              <el-upload
                class="avatar-uploader"
                :action="httpUrl + 'upload/uploadFile'"
                :show-file-list="false"
                :on-success="
                  (value) => {
                    handleAvatarSuccess(value, newMenuList);
                  }
                "
                :on-change="onChangeUpload"
                :before-upload="beforeAvatarUpload"
                :headers="headerObj"
                :data="{
                  type: 'menuList',
                  code: dataModificationCode,
                }"
              >
                <img
                  class="small_update_single_img avatar"
                  v-if="newMenuList.img"
                  :src="newMenuList.img"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
            <div class="small_update_single_mes">
              <div class="small_update_single_mes_input">
                <el-input
                  v-model="newMenuList.src"
                  placeholder="请输入跳转路径"
                ></el-input>
              </div>
              <div class="small_update_single_mes_input">
                <el-input
                  v-model="newMenuList.mes"
                  placeholder="请输入菜单名称"
                  maxlength="5"
                  show-word-limit
                ></el-input>
              </div>
              <div class="small_update_single_mes_button">
                <el-button
                  type="primary"
                  style="width: 100%"
                  @click="toAddRotationImg('menuList')"
                  >新增</el-button
                >
              </div>
            </div>
          </div>
          <!-- 已存在的列表 -->
          <div
            class="small_update_single"
            v-for="(item, index) in dataModificationMes.menuList"
            :key="item.id"
          >
            <div class="small_update_single_img_div">
              <el-upload
                class="avatar-uploader"
                :action="httpUrl + 'upload/uploadFile'"
                :show-file-list="false"
                :on-success="
                  (value) => {
                    handleAvatarSuccess(value, item);
                  }
                "
                :on-change="onChangeUpload"
                :before-upload="beforeAvatarUpload"
                :data="{
                  id: item.id,
                  type: 'menuList',
                  code: dataModificationCode,
                }"
                :headers="headerObj"
              >
                <img
                  class="small_update_single_img avatar"
                  v-if="item.img"
                  :src="item.img"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
            <div class="small_update_single_mes">
              <div class="small_update_single_mes_input">
                <el-input
                  v-model="item.src"
                  placeholder="请输入跳转路径"
                  @focus="smallUpdateInputFoncus('menuList', 'src', item)"
                  @blur="smallUpdateInputBlur(item)"
                ></el-input>
              </div>
              <div class="small_update_single_mes_input">
                <el-input
                  v-model="item.mes"
                  placeholder="请输入菜单名称"
                  maxlength="5"
                  show-word-limit
                  @focus="smallUpdateInputFoncus('menuList', 'mes', item)"
                  @blur="smallUpdateInputBlur(item)"
                ></el-input>
              </div>

              <div class="small_update_single_mes_button">
                <el-button
                  icon="el-icon-arrow-left"
                  :disabled="index == 0"
                  @click="
                    smallUpdateMoveLocation(
                      dataModificationMes.menuList,
                      index,
                      -1
                    )
                  "
                ></el-button>
                <!-- 删除 -->
                <el-tooltip
                  effect="dark"
                  content="删除"
                  placement="top-start"
                  :enterable="false"
                >
                  <el-button
                    type="danger"
                    icon="el-icon-delete"
                    @click="
                      deleteThisLabel(
                        dataModificationMes.menuList,
                        index,
                        item.id
                      )
                    "
                    >删除
                  </el-button>
                </el-tooltip>
                <el-button
                  icon="el-icon-arrow-right"
                  :disabled="index == dataModificationMes.menuList.length - 1"
                  @click="
                    smallUpdateMoveLocation(
                      dataModificationMes.menuList,
                      index,
                      1
                    )
                  "
                ></el-button>
              </div>
            </div>
          </div>
        </div>
        <el-divider content-position="left">图文列表</el-divider>
        <el-form-item label="标题">
          <el-input
            v-model="dataModificationMes.imageTextTitle"
            maxlength="100"
            show-word-limit
            placeholder="请输入图文列表标题"
            @focus="
              smallUpdateInputFoncus(
                'imageTextTitle',
                'imageTextTitle',
                dataModificationMes.imageTextTitle
              )
            "
            @blur="smallUpdateInputBlur(dataModificationMes)"
          ></el-input>
        </el-form-item>
        <div class="small_update_list">
          <!-- 新增 -->
          <div class="small_update_single">
            <div class="small_update_single_img_div">
              <el-upload
                class="avatar-uploader"
                :action="httpUrl + 'upload/uploadFile'"
                :show-file-list="false"
                :on-success="
                  (value) => {
                    handleAvatarSuccess(value, newTextImage);
                  }
                "
                :on-change="onChangeUpload"
                :before-upload="beforeAvatarUpload"
                :headers="headerObj"
                :data="{
                  type: 'imageText',
                  code: dataModificationCode,
                }"
              >
                <img
                  class="small_update_single_img avatar"
                  v-if="newTextImage.img"
                  :src="newTextImage.img"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
            <div class="small_update_single_mes">
              <div class="small_update_single_mes_input">
                <el-input
                  v-model="newTextImage.src"
                  placeholder="请输入跳转路径"
                ></el-input>
              </div>
              <div class="small_update_single_mes_input">
                <el-input
                  v-model="newTextImage.mes"
                  placeholder="请输入名称"
                  maxlength="10"
                  show-word-limit
                ></el-input>
              </div>
              <div class="small_update_single_mes_button">
                <el-button
                  type="primary"
                  style="width: 100%"
                  @click="toAddRotationImg('imageText')"
                  >新增</el-button
                >
              </div>
            </div>
          </div>
          <!-- 已存在的列表 -->
          <div
            class="small_update_single"
            v-for="(item, index) in dataModificationMes.imageTextList"
            :key="item.id"
          >
            <div class="small_update_single_img_div">
              <el-upload
                class="avatar-uploader"
                :action="httpUrl + 'upload/uploadFile'"
                :show-file-list="false"
                :on-success="
                  (value) => {
                    handleAvatarSuccess(value, item);
                  }
                "
                :on-change="onChangeUpload"
                :before-upload="beforeAvatarUpload"
                :data="{
                  id: item.id,
                  type: 'imageText',
                  code: dataModificationCode,
                }"
                :headers="headerObj"
              >
                <img
                  class="small_update_single_img avatar"
                  v-if="item.img"
                  :src="item.img"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
            <div class="small_update_single_mes">
              <div class="small_update_single_mes_input">
                <el-input
                  v-model="item.src"
                  placeholder="请输入跳转路径"
                  @focus="smallUpdateInputFoncus('imageText', 'src', item)"
                  @blur="smallUpdateInputBlur(item)"
                ></el-input>
              </div>
              <div class="small_update_single_mes_input">
                <el-input
                  v-model="item.mes"
                  placeholder="请输入名称"
                  maxlength="10"
                  show-word-limit
                  @focus="smallUpdateInputFoncus('imageText', 'mes', item)"
                  @blur="smallUpdateInputBlur(item)"
                ></el-input>
              </div>
              <div class="small_update_single_mes_button">
                <el-button
                  icon="el-icon-arrow-left"
                  :disabled="index == 0"
                  @click="
                    smallUpdateMoveLocation(
                      dataModificationMes.imageTextList,
                      index,
                      -1
                    )
                  "
                ></el-button>
                <!-- 删除 -->
                <el-tooltip
                  effect="dark"
                  content="删除"
                  placement="top-start"
                  :enterable="false"
                >
                  <el-button
                    type="danger"
                    icon="el-icon-delete"
                    @click="
                      deleteThisLabel(
                        dataModificationMes.imageTextList,
                        index,
                        item.id
                      )
                    "
                    >删除</el-button
                  >
                </el-tooltip>
                <el-button
                  icon="el-icon-arrow-right"
                  :disabled="
                    index == dataModificationMes.imageTextList.length - 1
                  "
                  @click="
                    smallUpdateMoveLocation(
                      dataModificationMes.imageTextList,
                      index,
                      1
                    )
                  "
                ></el-button>
              </div>
            </div>
          </div>
        </div>
      </el-form>
      <!-- 底部按钮 -->
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dataModificationVisible = false">取 消</el-button>
        <el-button type="primary" @click="toAdd">确 定</el-button>
      </span> -->
    </el-dialog>
    <!-- 关联生活号 -->
    <el-dialog
      title="关联生活号"
      :visible.sync="lifeRelation.visible"
      width="50%"
      @close="lifeRelationClose"
    >
      <el-form :model="editForm" label-width="70px">
        <!-- 公众号id -->
        <el-form-item label="公众号id">
          <el-input
            v-model="lifeRelation.lifeId"
            placeholder="请输入关联的生活号id"
            maxlength="100"
            show-word-limit
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部按钮 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="lifeRelationClose">取 消</el-button>
        <el-button type="primary" @click="editLifeRelationMes">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 复制布局 -->
    <el-dialog
      title="复制布局"
      :visible.sync="layoutCopyShow"
      width="50%"
      @close="closeCopyView"
    >
      <layoutCopy ref="layoutCopy" :refresh="getList" :labelCode="labelCode" />
    </el-dialog>
    <!-- 小程序配置 -->
    <el-dialog
      title="小程序配置"
      :visible.sync="configurationMes.visible"
      width="50%"
    >
      <smallroutineConfiguration
        ref="smallroutineConfigurationRef"
        :code="configurationMes.code"
        @refreshTable="getList"
      />
    </el-dialog>
  </div>
</template>
<script>
import smallroutineConfiguration from "./smallroutineConfiguration.vue";
import layoutCopy from "./layoutCopy.vue";
export default {
  name: "index",
  components: {
    layoutCopy,
    smallroutineConfiguration,
  },
  props: ["showMessage"],
  created() {
    this.httpUrl = this.$httpUrl;
    this.headerObj = {
      Authorization: window.sessionStorage.getItem("token"),
    };
    this.roleGrade = window.sessionStorage.getItem("roleGrade");
    // 获取当前需要展示的页码及条件(如果是消息模板返回的时候有值，没有则默认从第一页开始)
    this.getQueryInfo();
    // 获取表格列表
    this.getList();
    this.getPoiList();
  },
  data() {
    return {
      httpUrl: "",
      headerObj: {}, // token
      roleGrade: "", //角色等级
      queryInfo: {
        // 查询信息
        // 搜索输入栏动态model
        query: "",
        // 点击搜索的时候将model的值赋予该值
        queryMes: "",
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
      },
      queryInfoQyertOld: "", //搜索栏内原数据
      tableList: [], // 表格列表
      poiList: [], // poi列表
      total: 0, //总记录数
      addDialogVisible: false, //对话框显示状态
      dataModificationVisible: false, // 小程序页面展示数据编辑对话框显示状态
      dataModificationLoading: false, // 加载中
      //添加表单信息
      addForm: {
        name: "",
        code: "",
        appId: "",
        privateKey: "",
        publicKey: "",
        alipayPublicKey: "",
        // 服务列表
        poiType: ["", "", ""],
        // 插件开关
        rotationMap: true,
        menuList: true,
        imageText: true,
        authorization: true,
        message: true,
        status: true,
      },
      // 表单验证
      formRules: {
        name: [
          { required: true, message: "请输入名称", trigger: "blur" },
          {
            min: 1,
            max: 100,
            message: "长度在 1 到 100 个字符",
            trigger: "blur",
          },
        ],
        code: [
          { required: true, message: "请输入唯一标识", trigger: "blur" },
          {
            min: 1,
            max: 100,
            message: "长度在 1 到 100 个字符",
            trigger: "blur",
          },
        ],
        appId: [
          { required: true, message: "请输入appId", trigger: "blur" },
          {
            min: 1,
            max: 100,
            message: "长度在 1 到 100 个字符",
            trigger: "blur",
          },
        ],
        // poiType: [
        //   { required: true, message: "周边类型", trigger: "blur" },
        //   {
        //     min: 1,
        //     max: 100,
        //     message: "长度在 1 到 100 个字符",
        //     trigger: "blur",
        //   },
        // ],
      },
      //修改信息
      editForm: {
        poiType: [],
      },
      // 小程序页面展示数据
      dataModificationMes: {},
      // 小程序页面展示数据当前code
      dataModificationCode: "",
      //显示/隐藏修改栏
      editDialogVisible: false,
      // 加载中
      loadingPermission: false,
      // 页面数据编辑点击输入框获获取原本数据，用来判断是否有修改内容
      smallUpdateInputFoncusType: "",
      smallUpdateInputFoncussrcOrMes: "",
      smallUpdateInputFoncusMes: "",
      smallUpdateInputFoncusName: "",
      smallUpdateInputFoncusId: "",
      // 页面展示管理，轮播图新增
      newRotationImg: {
        img: "",
        src: "",
        mes: "",
      },
      // 页面展示管理，菜单列表新增
      newMenuList: {
        img: "",
        src: "",
        mes: "",
      },
      // 页面展示管理，图文列表新增
      newTextImage: {
        img: "",
        src: "",
        mes: "",
      },
      // 小程序关联生活号
      lifeRelation: {
        // 是否展示弹出框
        visible: false,
        // 生活号id
        lifeId: "",
        // 小程序id
        smallId: "",
      },
      // 小程序布局复制
      layoutCopyShow: false,
      labelCode: "",
      configurationMes: {
        visible: false,
        code: "",
      },
    };
  },
  methods: {
    getNewList() {
      this.queryInfo.pageNum = 1;
      this.getList();
    },
    // 获取表格列表查询条件
    getQueryInfo() {
      let query = this.$route.query;
      if (null == query || "" == query || undefined == query) {
        return;
      }
      let queryObj = query.queryInfo;
      if (null == queryObj || "" == queryObj || undefined == queryObj) {
        return;
      }
      this.queryInfo = queryObj;
    },
    // 获取表格列表
    async getList() {
      let queryInfo = this.queryInfo;
      if (queryInfo.query == undefined) {
        queryInfo = null;
        this.queryInfo = {
          // 查询信息
          // 搜索输入栏动态model
          query: "",
          // 点击搜索的时候将model的值赋予该值
          queryMes: "",
          pageNum: 1, //当前页
          pageSize: 10, //每页最大数
        };
      }
      const { data: res } = await this.$http.get("smallRoutine/list", {
        params: queryInfo,
      });
      if ("200" != res.code) {
        return this.$message.error("获取列表失败:" + res.message);
      }
      this.queryInfo.queryMes = this.queryInfo.query;
      this.tableList = res.message.records;
      this.total = res.message.total;
    },
    // 获取poi列表
    async getPoiList() {
      const { data: res } = await this.$http.get("poiCode/getAll");
      if ("200" != res.code) {
        return this.$message.error("获取poi列表失败:" + res.message);
      }
      this.poiList = res.message;
    },
    //最大数变更
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getList();
    },
    //当前页面变化触发
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage;
      this.getList();
    },
    // 修改状态
    async stateChange(e) {
      const { data: res } = await this.$http.put(`/smallRoutine/updateStatus`, {
        id: e.id,
        status: e.status,
      });
      if (res.code != "200") {
        e.status = !e.status;
        return this.$message.error(res.message);
      }
      this.$message.success("操作成功");
    },
    //监听添加
    addDialogClose() {
      this.$refs.addFormRed.resetFields();
    },
    // 添加
    async toAdd() {
      this.$refs.addFormRed.validate(async (valid) => {
        if (!valid) {
          return;
        }
        const { data: res } = await this.$http.post(
          "smallRoutine/add",
          this.addForm
        );
        if ("200" != res.code) {
          return this.$message.error("操作失败:" + res.message);
        }
        this.$message.success("操作成功");
        // 隐藏添加弹出层
        this.addDialogVisible = false;
        //刷新表格
        this.getList();
      });
    },
    // 小程序页面数据编辑
    async dataModification(e) {
      let { data: res } = await this.$http.get(
        "smallRoutineLabel/getListByCode?code=" + e
      );
      if ("200" != res.code) {
        return this.$message.error("操作失败:" + res.message);
      }
      this.dataModificationMes = res.message;
      this.dataModificationCode = e;
      this.dataModificationVisible = true; //显示对话框
    },
    // 小程序配置管理
    showConfiguration(e) {
      if (this.$refs.smallroutineConfigurationRef == undefined) {
        this.configurationMes.code = e;
      } else {
        this.$refs.smallroutineConfigurationRef.init(e);
      }
      this.configurationMes.visible = true;
    },
    // 页面数据编辑点击输入框获
    async smallUpdateInputFoncus(type, srcOrMes, e) {
      this.smallUpdateInputFoncussrcOrMes = srcOrMes;
      if ("src" == srcOrMes) {
        this.smallUpdateInputFoncusMes = e.src;
        this.smallUpdateInputFoncusId = e.id;
      } else if ("mes" == srcOrMes) {
        this.smallUpdateInputFoncusMes = e.mes;
        this.smallUpdateInputFoncusId = e.id;
      } else if ("name" == srcOrMes) {
        this.smallUpdateInputFoncusMes = e.name;
        this.smallUpdateInputFoncusId = e.id;
      } else if ("imageTextTitle" == srcOrMes) {
        // 图文列表标题
        this.smallUpdateInputFoncusMes = e;
      } else {
        return this.$message.warning("数据有误，请刷新后重试");
      }
      this.smallUpdateInputFoncusType = type;
    },
    // 小程序页面编辑点击input失去焦点触发
    async smallUpdateInputBlur(e) {
      let smallUpdateInputFoncussrcOrMes = this.smallUpdateInputFoncussrcOrMes;
      let newMes;
      if (smallUpdateInputFoncussrcOrMes == "src") {
        newMes = e.src;
      } else if (smallUpdateInputFoncussrcOrMes == "mes") {
        newMes = e.mes;
      } else if (smallUpdateInputFoncussrcOrMes == "imageTextTitle") {
        newMes = e.imageTextTitle;
      } else if (smallUpdateInputFoncussrcOrMes == "name") {
        newMes = e.name;
      } else {
        return this.$message.warning("数据类型有误，请刷新后重试");
      }
      let oldMes = this.smallUpdateInputFoncusMes;
      if (null == newMes || "" == newMes || undefined == newMes) {
        this.$message.warning("不能为空");
        if (smallUpdateInputFoncussrcOrMes == "src" && e.src != oldMes) {
          e.src = oldMes;
        } else if (smallUpdateInputFoncussrcOrMes == "mes" && e.mes != oldMes) {
          e.mes = oldMes;
        } else if (
          smallUpdateInputFoncussrcOrMes == "imageTextTitle" &&
          e.name != oldMes
        ) {
          e.imageTextTitle = oldMes;
        } else if (smallUpdateInputFoncussrcOrMes == "name") {
          e.name = e.oldMes;
        }
        return;
      }
      if (
        null == smallUpdateInputFoncussrcOrMes ||
        "" == smallUpdateInputFoncussrcOrMes ||
        undefined == smallUpdateInputFoncussrcOrMes
      ) {
        e.src = oldMes;
        return this.$message.warning("数据有误，请刷新后重试");
      }
      if (newMes == oldMes) {
        // 无修改
        return;
      }
      let that = this;
      // 有修改
      let { data: res } = await this.$http.put(
        "/smallRoutineLabel/updateSrcById",
        {
          id: that.smallUpdateInputFoncusId,
          mes: newMes,
          oldMes: oldMes,
          type: smallUpdateInputFoncussrcOrMes,
          code: that.dataModificationCode,
          updateType: that.smallUpdateInputFoncusType,
        }
      );
      if ("200" != res.code) {
        if (smallUpdateInputFoncussrcOrMes == "imageTextTitle") {
          e.imageTextTitle = oldMes;
        } else {
          e.src = oldMes;
        }
        return this.$message.error(res.message);
      }
      this.$message.success("操作成功");
    },
    // 小程序页面移动轮播图顺序
    async smallUpdateMoveLocation(list, index, sort, type) {
      let newSort = index + sort;
      if (0 > newSort) {
        this.$message.warning("已经是第一个了");
      } else if (newSort == list.length) {
        this.$message.warning("已经是最后一个了");
      }
      // let that = this;
      // 有修改
      let { data: res } = await this.$http.put(
        "/smallRoutineLabel/updateMoveLocation",
        {
          id: list[index].id,
          toId: list[newSort].id,
          type: type,
        }
      );
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      if (sort == -1) {
        // 前移
        list.splice(index - 1, 0, list[index]);
        //删除后一项
        list.splice(index + 1, 1);
      } else if (sort == 1) {
        // 后移
        // 在下一项插入该项
        list.splice(index + 2, 0, list[index]);
        // 删除前一项
        list.splice(index, 1);
      }
      this.$message.success("操作成功");
    },
    // 轮播图文件上传成功
    handleAvatarSuccess(file, item) {
      item.img = file.message.src;
    },
    // 图片上传前
    beforeAvatarUpload(file) {
      // const isJPG = file.type === "image/jpeg";
      // if (!isJPG) {
      //   this.$message.error("上传头像图片只能是 JPG 格式!");
      // }
      const maxSize = file.size / 1024 / 1024 < 10;
      if (!maxSize) {
        this.$message.error("上传头像图片大小不能超过 10MB!");
      }
      return maxSize;
    },
    onChangeUpload() {
      this.dataModificationLoading = !this.dataModificationLoading;
    },
    // 轮播图点击新增
    async toAddRotationImg(type) {
      let img;
      let src;
      let mes;
      let status;
      let name;
      let code = this.dataModificationCode;
      if (type == "rotationImg") {
        // 轮播图
        let newRotationImg = this.newRotationImg;
        img = newRotationImg.img;
        src = newRotationImg.src;
        mes = newRotationImg.mes;
        if (null == img || "" == img || undefined == img) {
          return this.$message.warning("请先上传图片");
        } else if (null == src || "" == src || undefined == src) {
          return this.$message.warning("跳转路径不能为空");
        }
      } else if (type == "menuList") {
        // 菜单列表
        let newMenuList = this.newMenuList;
        img = newMenuList.img;
        src = newMenuList.src;
        mes = newMenuList.mes;
        if (null == img || "" == img || undefined == img) {
          return this.$message.warning("请先上传图片");
        } else if (null == src || "" == src || undefined == src) {
          return this.$message.warning("跳转路径不能为空");
        } else if (null == mes || "" == mes || undefined == mes) {
          return this.$message.warning("名称不能为空");
        }
      } else if (type == "imageText") {
        // 图文列表
        let newTextImage = this.newTextImage;
        img = newTextImage.img;
        src = newTextImage.src;
        mes = newTextImage.mes;
        if (null == img || "" == img || undefined == img) {
          return this.$message.warning("请先上传图片");
        } else if (null == src || "" == src || undefined == src) {
          return this.$message.warning("跳转路径不能为空");
        }
      } else {
        return this.$message.warning("类型有误，请刷新后重试");
      }
      let { data: res } = await this.$http.post("/smallRoutineLabel/addLabel", {
        code: code,
        img: img,
        src: src,
        mes: mes,
        type: type,
        status: status,
        name: name,
      });
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      // 轮播图数组插入新增数据
      let dataModificationMes = this.dataModificationMes;
      let labelList = [];
      if ("rotationImg" == type) {
        // 轮播图
        // 拼接函数(索引位置, 要删除元素的数量, 元素)
        labelList = dataModificationMes.rotationImgList;
        this.newRotationImg = {};
      } else if ("menuList" == type) {
        labelList = dataModificationMes.menuList;
        this.newMenuList = {};
      } else if ("imageText" == type) {
        labelList = dataModificationMes.imageTextList;
        this.newTextImage = {};
      }
      labelList.splice(0, 0, res.message);
    },
    // 删除小程序label信息
    async deleteThisLabel(list, index, id) {
      const result = await this.$confirm("此操作将永久删除,是否继续?", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result != "confirm") {
        //取消删除
        return;
      }
      let res;
      let data = await this.$http.delete("/smallRoutineLabel/del?id=" + id);
      res = data.data;
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      // 从inedx的位置开始向后删除1个元素
      list.splice(index, 1);
      this.$message.success("删除成功");
      this.getList(); //刷新表格
    },
    // 删除信息
    async deleteThis(id) {
      const result = await this.$confirm("此操作将永久删除,是否继续?", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result != "confirm") {
        //取消删除
        return;
      }
      const { data: res } = await this.$http.delete(
        "/smallRoutine/del?id=" + id
      );
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.$message.success("删除成功");
      this.getList(); //刷新表格
    },
    // 消息模板
    messageTemplate(e) {
      let appId = e.appId;
      if (null == appId || "" == appId || undefined == appId) {
        return this.$message.warning("请先设置小程序的appId");
      }
      let code = e.code;
      if (null == code || "" == code || undefined == code) {
        return this.$message.warning("请先设置小程序的唯一标识码");
      }
      this.$router.push({
        path: "/messageTemplate",
        query: {
          appId: appId,
          code: code,
          queryInfo: this.queryInfo,
          from: "/smallRoutine",
          smallRoutineName: "1",
        },
      });
    },
    // 服务管理
    serviceManagement(e) {
      let code = e.code;
      if (null == code || "" == code || undefined == code) {
        return this.$message.warning("请先设置小程序的唯一标识");
      }
      switch (code) {
        case "gdls1":
        case "gdls2":
        case "gdls3":
          // 高德临时1、2、3打开新页面
          this.showMessage(code);
          break;
        default:
          this.$router.push({
            path: "/smallRoutineServiceRel",
            query: {
              code: code,
              queryInfo: this.queryInfo,
              from: "/smallRoutine",
              smallRoutineName: "1",
            },
          });
          break;
      }
    },
    // 更改信息对话框
    async showEditDialog(e) {
      const { data: res } = await this.$http.get(
        "smallRoutine/getMesById?id=" + e.id
      ); //获取内容
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.editForm = res.message;
      this.editDialogVisible = true; //显示对话框
    },
    dataModificationClose() {
      this.editDialogVisible = false;
      this.newRotationImg = {};
    },
    //确认修改
    editMes() {
      this.$refs.editFormRed.validate(async (valid) => {
        if (!valid) return;
        //发起请求
        const { data: res } = await this.$http.put(
          "/smallRoutine/update",
          this.editForm
        );
        if ("200" != res.code) {
          return this.$message.error(res.message);
        }
        this.$message.success("修改成功");
        // 隐藏添加弹出层
        this.editDialogVisible = false;
        //刷新表格
        this.getList();
      });
    },
    // 权限修改
    renderFunc(h, option) {
      // 穿梭框标签样式
      return <span>{option.label}</span>;
    },
    // 关闭授权加载中状态
    colseLoadingPermission() {
      let that = this;
      setTimeout(() => {
        if (that.loadingPermission) {
          that.loadingPermission = false;
          that.$message.error("连接服务器超时");
        }
      }, that.$lodingMaxTime);
    },
    // input校验不能中文
    inputCheck(e) {
      if (/.*[\u4e00-\u9fa5]+.*$/.test(e.code)) {
        this.$message.error("小程序唯一码不能含有中文！");
      }
    },
    // 关联生活号
    // 打开弹出层
    async lifeRelationVisibleShow(id) {
      const { data: res } = await this.$http.get(
        "smallRoutine/getLifeIdById?id=" + id
      ); //获取内容
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.lifeRelation.smallId = id;
      this.lifeRelation.lifeId = res.message;
      this.lifeRelation.visible = true;
    },
    // 确定修改
    async editLifeRelationMes() {
      let lifeRelation = this.lifeRelation;
      let smallId = lifeRelation.smallId;
      if (null == smallId || "" == smallId || undefined == smallId) {
        return this.$message.error("操作失败,小程序id丢失，请刷新后重试");
      }
      const { data: res } = await this.$http.put(
        "smallRoutine/updateLifeIdById",
        lifeRelation
      );
      // 返回结果
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.$message.success("操作成功");
    },
    //关闭关联生活号窗口
    lifeRelationClose() {
      // 清除生活号与小程序id
      this.lifeRelation.smallId = "";
      this.lifeRelation.lifeId = "";
      // 隐藏弹出层
      this.lifeRelation.visible = false;
    },
    // 修改服务状态
    async serviceRelStatusChange(status, id) {
      if (null == id || "" == id || undefined == id) {
        return this.$message.warning("id不能为空,请刷新后重试");
      }
      const { data: res } = await this.$http.put(
        "smallRoutineServiceRel/updateStatus",
        {
          id,
          status,
        }
      );
      // 返回结果
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.$message.success("操作成功");
    },
    // 显示布局复制页面
    showLayoutCoty(e) {
      this.labelCode = e;
      this.layoutCopyShow = true;
    },
    // 关闭复制页面
    closeCopyView() {
      this.$refs.layoutCopy.clearForm();
    },
  },
};
</script>
<style lang="less" scoped>
.el-breadcrumb {
  margin-bottom: 15px;
  font-size: 17px;
}
.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
/deep/.el-form-item__label {
  width: 100px !important;
}
/deep/.el-form-item__content {
  margin-left: 100px !important;
}
// 小程序页面展示编辑
.small_update_list {
  display: flex;
  flex-wrap: wrap;
  .small_update_single {
    // 单个对象
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    width: 280px;
    margin-left: 10px;
    // 图片
    .small_update_single_img_div {
      /deep/.el-upload {
        width: 100% !important;
        height: 200px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px !important;
      }
      .small_update_single_img {
        width: 100%;
        height: 100%;
      }
    }
    // 图片下信息
    .small_update_single_mes {
      width: 100%;
      .small_update_single_mes_input {
        margin: 5px 5px;
      }
      .small_update_single_mes_button {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 0 5px 5px 5px;
      }
    }
  }
}
</style>
