<template>
  <div class="div_main">
    <el-form
      :model="updateForm"
      ref="updateFormRed"
      label-width="auto"
      v-loading="showLoading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
		<el-divider content-position="left">首页自动跳转</el-divider>
			<!-- 跳转h5 -->
			<el-form-item label="最终首页跳转h5" prop="jumpToH5">
				<el-input  v-model="updateForm.jumpToH5"  placeholder="请输入跳转h5"  clearable></el-input>
			</el-form-item>
			<!-- 是否开启 -->
			<el-form-item label="最终跳转h5" prop="jumpToH5Status">
				<el-switch v-model="updateForm.jumpToH5Status"> </el-switch>
			</el-form-item>
			<!-- 是否开启 -->
			<el-form-item label="第一次跳转h5" prop="isPreJumpToH5">
				<el-switch v-model="updateForm.isPreJumpToH5"> </el-switch>
			</el-form-item>
			<!-- 跳转h5 -->
			<el-form-item label="第一次跳转h5" prop="preJumpToH5">
				<pre_jump_h5_list_editor		:input_list.sync="updateForm.preJumpToH5"></pre_jump_h5_list_editor>
			</el-form-item>
		<el-divider content-position="left">生活号</el-divider>
			<!-- 是否开启生活号 -->
			<el-form-item label="生活号" prop="jumpToH5">
				<el-switch v-model="updateForm.isShowLifeId"> </el-switch>
			</el-form-item>
			<el-form-item label="生活号列表" prop="lifeIdJsonArray">
				<life_id_list_editor		:input_list.sync="updateForm.lifeIdJsonArray"></life_id_list_editor>
			</el-form-item>
		<el-divider content-position="left">其他功能</el-divider>
			<!-- 灯火广告 -->
			<el-form-item label="灯火广告" prop="lightAdStatus">
				<el-switch v-model="updateForm.lightAdStatus"> </el-switch>
			</el-form-item>
			<!-- 轮播图 -->
			<el-form-item label="轮播图" prop="rotationMap">
				<el-switch v-model="updateForm.rotationMap"	@change="rotationMapChange"> </el-switch>
			</el-form-item>
		
			<el-form-item label="弹窗小广告" prop="coupon">
				<el-switch v-model="updateForm.coupon"> </el-switch>
			</el-form-item>
		<!-- 广告部分 -->
		<el-divider content-position="left"	>广告图片</el-divider>
			<el-form-item label="是否展示" 		prop="isShowAdvertiseImage"	>
				<el-switch v-model="updateForm.isShowAdvertiseImage"> </el-switch>
			</el-form-item>
			<el-form-item label="跳转的类型" prop="advertiseImageJumpType">
				<el-select v-model="updateForm.advertiseImageJumpType" placeholder="请选择">
					<el-option		label="h5"	value="h5"></el-option>
					<el-option		label="app"	value="app"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="跳转的h5路径" prop="advertiseImageH5">
				<el-input  v-model="updateForm.advertiseImageH5"  placeholder="请输入跳转h5"  clearable></el-input>
			</el-form-item>
			<el-form-item label="跳转的appId" prop="advertiseImageAppId">
				<el-input  v-model="updateForm.advertiseImageAppId"  placeholder="请输入跳转的appId"  clearable></el-input>
			</el-form-item>
			<el-form-item label="图片设置" >
					<img class="div_main_img"	 :src="updateForm.advertiseImageUrl" />
					<cdn_image_choose		@submit="cdn_image_submit"></cdn_image_choose>
			</el-form-item>
			<el-form-item label="菜单按钮" prop="smallMallMenuTemplateId">
				<small_mall_menu_picker	v-if="updateForm.smallMallMenuTemplateId"	
					:smallMallMenuTemplateId.sync="updateForm.smallMallMenuTemplateId"></small_mall_menu_picker>
			</el-form-item>
			
			<!-- <el-form-item label="弹窗内容">
				<el-table	:data="lucard_pop.app_current_pop"	v-if="lucard_pop.app_current_pop.length != 0">
					<el-table-column v-for="(item,index) in lucard_pop.columnName" :width="item.width" :label="item.label" :prop="item.prop"  :key="index"> -->
						<!-- 防止某一列中的数据过长，导致某些行的高度偏高，在这里如果长度超过一定长度，会自动缩减到30个字符，然后全部内容会用一个小窗口显示 -->
						<!-- <template slot-scope="scope"	>
							<el-popover	v-if="(typeof scope.row[item.prop] == 'string') && scope.row[item.prop].length >30"	width="500"	trigger="hover"	:content="scope.row[item.prop]">
								<span slot="reference">{{scope.row[item.prop].slice(0,30)+'...'}}</span>
							</el-popover>
							<span	v-else>{{scope.row[item.prop]}}</span>
						</template>
					</el-table-column>
				</el-table>
				<div	v-else>当前为空,请选择一条模板记录把</div>
				<el-button  type="primary"	style="margin-left: 100px;"  @click="addPopTemplate()">选择一条模板记录</el-button>
				<el-select v-model="lucard_pop.temp_data_id" placeholder="请选择">
					<el-option	v-for="(item,index) in lucard_pop.template_list"	:key="index" 	:label="item.title+'-'+item.jumpType" :value='item.id'></el-option> 
				</el-select>
			</el-form-item> -->
		<!-- 新人红包 -->
		<el-divider content-position="left">首页展示红包</el-divider>
			<el-form-item label="口令红包" prop="showRedEnvelopeForNewcomers">
				<el-switch v-model="updateForm.isShowPasswordsRedEnvelope"></el-switch>
			</el-form-item>
			<el-form-item label="新人红包(表单)" prop="showRedEnvelopeForNewcomers">
					<el-switch v-model="updateForm.showRedEnvelopeForNewcomers"></el-switch>
			</el-form-item>
			<el-form-item label="注册领红包" prop="isShowRegisterForRedpacket">
					<el-switch v-model="updateForm.isShowRegisterForRedpacket"></el-switch>
			</el-form-item>
			<el-form-item label="注册领红包h5" prop="registerForRedpacketUrl">
				<el-input  v-model="updateForm.registerForRedpacketUrl"  placeholder="请输入跳转h5"  clearable></el-input>
			</el-form-item>
				<!-- 	v-if="showSingleMes.appletType == 50" -->
		<el-divider content-position="left">云码</el-divider>
			<el-form-item label="是否展示云码内容"	 prop="isShowYunma">
				<el-switch v-model="updateForm.isShowYunma" ></el-switch>
			</el-form-item>
			<el-form-item label="是否展示入会"	 prop="isShowYunmaMembership">
				<el-switch v-model="updateForm.isShowYunmaMembership" ></el-switch>
			</el-form-item>
			<el-form-item label="是否展示聚合拉新"	 prop="isShowYunmaAcquisition">
				<el-switch v-model="updateForm.isShowYunmaAcquisition" ></el-switch>
			</el-form-item>
		<el-divider content-position="left">淘宝客</el-divider>
			<el-form-item label="是否展示淘宝客内容"	 prop="isShowYunma">
				<el-switch v-model="updateForm.isShowTaobao" ></el-switch>
			</el-form-item>
		<el-divider content-position="left">是否跳转卡</el-divider>
			<el-form-item label="是否跳转卡" prop="isShowTemplateContent">
				<el-switch v-model="updateForm.isShowTemplateContent" @change="isShowTemplateContentChange()"></el-switch>
			</el-form-item>
		<el-divider content-position="left">标题的修改</el-divider>
			<el-form-item label="修改小程序的标题" prop="indexTitle">
				<el-input  v-model="updateForm.indexTitle"  placeholder="请输入小程序的标题"  clearable></el-input>
			</el-form-item>
		<el-divider content-position="left">小程序的客服</el-divider>
			<el-form-item label="小程序的客服" prop="customerServiceFaq">
				<customer_service_faq	:input_list.sync="updateForm.customerServiceFaq"></customer_service_faq>
			</el-form-item>
		
		<el-divider content-position="left">底部导航栏</el-divider>
			<el-form-item label="底部导航栏" prop="customerServiceFaq">
				<navigation_bar	:input_list.sync="updateForm.navigationBar"></navigation_bar>
			</el-form-item>
		<el-divider content-position="left">小商城</el-divider>
			<jump_object_parse	:jump_object_json.sync="updateForm.smallMallSwiperJson"		content_title="顶部轮播图"></jump_object_parse>
		<el-divider content-position="left">首页</el-divider>
			<jump_object_parse	:jump_object_json.sync="updateForm.homeSwiperJson"		content_title="顶部轮播图"></jump_object_parse>
		<el-divider content-position="left">记账本</el-divider>
			<jump_object_parse	
				:jump_object_json.sync="updateForm.accountBookSwiperJson"	
				prefix_path="redpacket/accountBook/swiper"		
				content_title="底部轮播图"
			></jump_object_parse>
		<el-divider content-position="left">只有轮播图的首页</el-divider>
			<jump_object_parse	
				:jump_object_json.sync="updateForm.indexSwiperJson"	
				prefix_path="redpacket/swiper/swiper"		
				content_title="整个轮播图"
			></jump_object_parse>
		<el-divider content-position="left">商城</el-divider>
			<el-form-item label="商城顶部轮播图" prop="mallIndexSwiperUrl">
				<image_choose	:image_url.sync="updateForm.mallIndexSwiperUrl"	prefix_path="redpacket/mall/indexSwiper"></image_choose>
			</el-form-item>
			<el-form-item label="商城首页要展示的商品列表" prop="mallIndexGoodsList">
				<mall_goods_list	:input_list.sync="updateForm.mallIndexGoodsList"></mall_goods_list>
			</el-form-item>
			<el-form-item label="商城首页轮播图条轮的h5路径">
				<el-input v-model="updateForm.mallIndexSwiperH5Url" placeholder="请输入跳转h5链接"></el-input>
			</el-form-item>
			<el-form-item label="商城首页轮播图条轮的h5路径开关">
				<el-switch v-model="updateForm.mallIsSwiperH5Url" active-color="#13ce66" style="display: flex; margin-top: 9px;"></el-switch>
			</el-form-item>
		<el-divider content-position="left">自定义广告第一条</el-divider>
			<el-form-item label="是否开启" prop="isCustomAdvertise">
				<el-switch v-model="updateForm.isCustomAdvertise"> </el-switch>
			</el-form-item>
			<el-form-item label="类别" prop="customAdvertiseType">
				<customerAdvertiseType  :type.sync="updateForm.customAdvertiseType"></customerAdvertiseType>
			</el-form-item>
		<el-divider content-position="left">粉丝群</el-divider>	
			<fan_group_editor	
				:isShowFanGroup.sync = "updateForm.isShowFanGroup"
				:fanGroupTemplateId.sync = "updateForm.fanGroupTemplateId"
				:fanGroupTopLeftSlogan.sync = "updateForm.fanGroupTopLeftSlogan"
				v-if="is_load_success">
			</fan_group_editor>
		<el-divider content-position="left">翻一翻</el-divider>	
			<overturn	
				:overturnJson.sync="updateForm.overturnJson"
				v-if="is_load_success">
			</overturn>
		<!-- <el-divider content-position="left">消息轮播</el-divider>
			<el-form-item label="注册领红包h5" prop="messageCarousel">
				<message_carousel	
					:input_list.sync="updateForm.messageCarousel">
				</message_carousel>
			</el-form-item> -->
			
    </el-form>
    <!-- 底部按钮 -->
    <div class="buttomButton">
      <el-button type="primary" @click="tosave">确 定</el-button>
    </div>
  </div>
</template>
<script>
import {
  getConfiguration,
  updateConfiguration,
} from "@/services/thirdParty/smallRoutine/applet1/configuration.js";
import axios from 'axios';
import cdn_image_choose from './smallroutineConfiguration/cdn_image_choose.vue'
import life_id_list_editor from './smallroutineConfiguration/life_id_list_editor.vue'
import pre_jump_h5_list_editor from './smallroutineConfiguration/pre_jump_h5_list_editor.vue'
import customer_service_faq from './smallroutineConfiguration/customer_service_faq.vue'
import navigation_bar from './smallroutineConfiguration/navigation_bar.vue'
import small_mall_menu_picker from "./smallroutineConfiguration/small_mall_menu_picker.vue";
import jump_object_parse from "./smallroutineConfiguration/jump_object_parse.vue";
import mall_goods_list from "../../../smallRoutine/applet1/childrenPage/smallroutineConfiguration/mall_goods_list.vue"
import image_choose from './smallroutineConfiguration/image_choose.vue'	//图片选择器
import customerAdvertiseType from './smallroutineConfiguration/redpacket/customAdvertiseType_choose.vue'
import fan_group_editor from "./smallroutineConfiguration/fan_group_editor.vue"; 
import message_carousel from "./smallroutineConfiguration/message_carousel.vue";
import overturn from './smallroutineConfiguration/overturn'

export default {
  props: ["showSingleMes","showSingleId", "poiList"],
  name: "smallroutineConfiguration",
  components:{
    cdn_image_choose, life_id_list_editor, pre_jump_h5_list_editor, customer_service_faq, navigation_bar, small_mall_menu_picker,
    jump_object_parse,mall_goods_list,image_choose,customerAdvertiseType,fan_group_editor,overturn
},
  data() {
    return {
		updateForm: {
			poiType: [],
		},
		showLoading: false,
		is_load_success:false,	//数据是否加载完成
		httpUrl:this.$httpUrl,
		lucard_pop:{
			template_list:[],
			app_current_pop:[],
			temp_data_id:null,
			columnName:[	//每一列的数据
				{label:"标题",prop:"title",map_type:'none',width:120},
				{label:"跳转类型",prop:"jumpType",map_type:'base',map:{'h5':'h5','api':'api'},width:100},
				{label:"是否有提示",prop:"isShowNotification",map_type:'boolean',map:{'false':'无','true':'有'} ,width:100},
				{label:"h5路径",prop:"h5Path",map_type:'none',width:250},
				{label:"等待时间",prop:"waitingTime",map_type:'none' ,width:100},
				{label:"产品码",prop:"posterCode",map_type:'none' ,width:280},
				// {label:"图片路径",prop:"imagePath",map_type:'image' ,width:0},     
			],
		}
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getMes();
    },
    async getMes() {
		const loading = this.$loading({
			lock: true,
			text: "Loading",
			spinner: "el-icon-loading",
			background: "rgba(0, 0, 0, 0.7)",
		});
		let res = await getConfiguration(this.showSingleId);
		loading.close();
		this.is_load_success = true;
		if (!res.success) {
			return this.$message.error(res.err);
		}
	   this.updateForm = res.data;
	  //获取lucard的弹窗内容
		//1.获取所有模板弹窗
		let res_lucard_pop	= await axios.get(this.httpUrl+"tb-third-party-wang-lucard-card/listAllPop");
		this.lucard_pop.template_list = res_lucard_pop.data ;
		//2.获取改app的弹窗
		this.lucard_pop.app_current_pop = (await axios.get(this.httpUrl+"tb-third-party-wang-lucard-card/listAppAllCardPop?appId="+this.showSingleMes.appId)).data.message;
    },
    tosave() {
      this.$refs["updateFormRed"].validate((valid) => {
        if (!valid) {
          return;
        }
        let updateForm = this.updateForm;
        if (updateForm.jumpToH5Status && this.isnull(updateForm.jumpToH5)) {
          return this.$message.error(
            "首页自动跳转开关开启时跳转的h5路径不能为空"
          );
        }
        this.save();
      });
    },
    async save() {
      let res = await updateConfiguration(this.updateForm);
      if (!res.success) {
        return this.$message.error(res.err);
      }
      this.$message.success("操作成功");
    },
    isnull(e) {
      return e == null || e == undefined || e == "";
    },
		isShowTemplateContentChange(){
			if(this.updateForm.isShowTemplateContent ){
				this.updateForm.rotationMap =false;
			}
		},
		rotationMapChange(){
			if(this.updateForm.rotationMap ){
				this.updateForm.isShowTemplateContent =false;
			}
		},
		uploadSuccessHandle(res){
				// console.log(res);
				if(res.code == 200){
					this.updateForm.popImage =res.message;
				}else{
					this.$message.error("上传图片失败");
				}
				
		},
		
		async	addPopTemplate(){
			if(this.lucard_pop.temp_data_id == null){
				return this.$message.error("请先选择模板内容")
			}
			const result = await axios({
					url:this.$httpUrl + 'tb-third-party-wang-lucard-card/addCardForAppPop',
					method:'POST',
					data:{ id:this.lucard_pop.temp_data_id,appId:this.showSingleMes.appId }
			})
			if(result.data.code != 200 ){
				return	this.$message.error("添加失败")
			}
			this.$message.success("添加成功")
			this.init();
			// console.log(result)
		},
		
		//cdn_image_choose组件上传图片的路径,并且更改图片
		async	cdn_image_submit(path){
			this.updateForm.advertiseImageUrl = path;
			const result = await axios({
					url:this.$httpUrl + 'thirdPartySmallRoutineConfiguration/uploadAdvertiseImage',
					method:'POST',
					data:{ id:this.showSingleMes.id,advertiseImageH5:path }
			})
			if(result.data.code != 200 ){
				return	this.$message.error("图片更改失败")
			}
			this.$message.success("图片更改成功")
		}
  },
	
};
</script>

<style lang="less" scoped>
	.div_main_img{
		margin-top: 10px;
		width: 120px;
		height: 120px;
	}
</style>
