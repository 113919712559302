<template>
    <div		class="container">
		<div	class="mask"  v-if="is_show_mask">
			<div 	class="loading_text"	>正在加载中</div>
			<img  class="loading_icon"	src="./image/Loading.svg"	/>
		</div>
		<el-button type="primary" @click="add">添加</el-button>
		<el-button type="primary" @click="clear">清空</el-button>
		<el-table	:data="list">
				<el-table-column  label="类别">
					<template slot-scope="scope">
						<el-select :value="scope.row.type" placeholder="请选择"	@change="type=>{
							list[scope.$index]  = navigation_type_options.find(e=>e.type==type);list.push({});list.pop();
						}">
							<el-option	v-for="(item,index) in navigation_type_options"	
								:key="index"	:label="item.label"	:value="item.type"></el-option>
						</el-select>
					</template>
				</el-table-column>
				<el-table-column  label="顶部左上角标题"  width="150">
						<template slot-scope="scope">
								<el-input v-model="scope.row.title" placeholder="标题为空为本来的默认值"	clearable></el-input>
						</template>
				</el-table-column>
				<el-table-column  label="底部名字"  width="150">
						<template slot-scope="scope">
								<el-input v-model="scope.row.name" placeholder="不能为空"	clearable></el-input>
						</template>
				</el-table-column>
				<el-table-column  label="排序"	width="150">
					<template slot-scope="scope"	>
						<el-button type="primary" @click="row_up(scope.$index)"	:disabled="scope.$index==0"	size="mini">向上</el-button>
						<el-button type="primary" @click="row_down(scope.$index)" :disabled="scope.$index==list.length-1"	size="mini">向下</el-button>
					</template>
				</el-table-column>
				<el-table-column  label="操作"  width="80">
					<template slot-scope="scope"	>
						<el-button type="primary" @click="delete_row(scope.$index)" size="mini">删除</el-button>
					</template>
				</el-table-column>
		</el-table>
    </div>
</template>

<script>
	import axios from 'axios'
export default{
    components:{
		
    },
    props: {
		input_list:{	type:String },
		list_max_length:{	type:Number, default:5}
    },
    data() {
        return {
			// 这个是数据列表，在created那边有初始化，对input_list就是输入进来的String格式的列表进行json的转化，
			// 转化为Array类型的列表
            list:[],
            is_first_watch:true,
            is_show_mask:true,
            navigation_type_options:[
              {label:'红包模板',type:'redpacket',name:"主页",title:'首页'},
              {label:'客服中心',type:'customerService',name:'客服',title:'客服中心'},
              {label:'记账本',type:'accountBook',name:'主页',title:'首页'},
              {label:'扭蛋机',type:'lotteryEgg',name:'扭蛋机',title:'扭蛋机'},
              {label:'小商城',type:'smallMall',name:'商城',title:'商城'},
              {label:'轮播图',type:'swiper',name:'首页',title:'首页'},
              {label:'商城',type:'mall',name:'首页',title:'首页'},
              {label:'首页',type:'home',name:'首页',title:'首页'},
              {label:'我的',type:'my',name:'我的',title:'我的'},
              {label:'签到',type:'sign',name:'签到',title:'签到'},
              {label:'花语',type:'flowerLanguage',name:'花语',title:'花语'},
              {label:'扫一扫1',type:'scan',name:'扫一扫',title:'扫一扫'},
              {label:'扫一扫2',type:'scan2',name:'扫一扫',title:'扫一扫'},
              {label:'翻一翻',type:'overturn',name:'翻一翻',title:'翻一翻'},
              {label:'转盘',type:'turnplate',name:'转盘',title:'转盘'},
              {label:'幸运抽奖',type:'lucky_draw',name:'幸运抽奖',title:'幸运抽奖'},
              {label:'打车',type:'taking_car',name:'打车',title:'打车'},
              {label:'星座',type:'constellation',name:'星座',title:'星座'},
              {label:'性格测试',type:'personality',name:'性格测试',title:'性格测试'},
              {label:'成语',type:'idioms',name:'成语',title:'成语'},
            ]
        }
    },
    methods:{
//对数据进行增删改查的操作
		//添加一行数据
        add(){
			if(this.list_max_length == this.list.length)	{	//如果长度超过了，就不要再添加了
				this.$message.error("超过长度了,无法再添加");
			}else{
				this.list.push(this.navigation_type_options[0]);	//默认添加第一个
			}
        },
		//排序的名次上升，指排序的下标上升
        row_up(index){
        	let temp = this.list[index] ;
        	this.$set(this.list,index,this.list[index-1]);
        	this.$set(this.list,index-1,temp);
        },
		//排序的名次下降，指排序的下标下降
        row_down(index){
        	let temp = this.list[index] ;
        	this.$set(this.list,index,this.list[index+1]);
        	this.$set(this.list,index+1,temp);
        },
		//删除一行的数据
        delete_row(index){
        	this.list.splice(index,1);
        },
		//清空所有的数据
        clear(){
        	this.list =[];
        },
		change(e){
			console.log(e);
		},
		
//下面处理初始化数据的，因为props进来的数据比较慢，需要等props内的参数进来才开始初始化的操作
		//根据输入进来的数据 处理
		async	initiailize_data(){
			//先进行解码（在网络传输中会变内容，所以传输的时候会进行编码解码）
			//后对于JSON格式经行 转化
			this.list = JSON.parse(decodeURI(this.input_list)) ;
		},
		//在本地数据还没有初始化之前，把页面内容遮盖，然后轮询props是否初始化完成，再进行本地数据初始化
		wait_util_not_undefined(callbackFunction){
			const  intervalTimer = setInterval(
				()=>{
					if(this.input_list != undefined){
						this.initiailize_data();
						clearInterval(intervalTimer);
						this.is_show_mask = false; 
					}
				},
				100
			)
		}
    },
    computed:{
		
	},
    created(){
		this.wait_util_not_undefined();
    },
	watch:{
		//对结果进行解码，上传给父组件
		list:{
			deep:true,
			handler(newValue){
				this.$emit("update:input_list",encodeURI(JSON.stringify(newValue)));
			}
		}
	}
}
</script>

<style  lang="less" scoped>
	.container{
		position: relative;
	}
	.mask{
		position: absolute;
		z-index: 200;
		background-color: white;
		opacity: 0.8;
		width: 100%;
		height: 100%;
		
		display: flex;	justify-content: center;	align-items: center;
		
		.loading_text{
			font-size: 23px;
		}
		
		.loading_icon{
			margin-left: 6px;
			width: 30px;		height: 30px;
			animation-name: rotate;
			animation-duration: 2s;
			animation-timing-function: linear;
			animation-iteration-count: infinite;
		}
		@keyframes rotate {
			0%		{	transform: rotate(0deg);	}
			50%		{	transform: rotate(180deg);	}
			100%		{	transform: rotate(360deg);	}
		}
	}
</style>