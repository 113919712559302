<template>
<el-dialog title="查看uv" :visible="true"	class="dialog"	width="90%"	top="6vh"	@close="close">
	<div class="table" >
		<div	class="left table_content">
			<h2>今天当前的UV统计值:{{today_uv}}</h2>
			<h4>一小时内的UV</h4>
			<div id="echart_minutes" class="echart-minutes"></div>
			<h4>一天之内的UV(灰色代表昨天)</h4>
			<div id="echart_hour" class="echart-minutes"></div>
		</div>
		<div	class="right table_content"	>
			<h2>本周当前的UV统计值:{{this_week_uv}}</h2>
			<h4>本周内的UV</h4>
			<div id="echart_this_week" class="echart-minutes"></div>
			<h4>上周内的UV(总额：{{last_week_uv}})</h4>
			<div id="echart_last_week" class="echart-minutes"></div>
		</div>
	</div>
<!-- 	<div	class="month_table">
		<h4>一个月内的UV :</h4>
		<div id="echart_month" class="echart-month"></div>
	</div> -->
	<div slot="footer" > 
		<el-button @click="close">关闭页面</el-button>
	</div>
</el-dialog>
</template>

<script>
	import * as echarts from  "echarts"  
	import axios from 'axios';
export default{
    components:{
        
    },
    props: {
        small_routine_id:{type:String}
    },
    data() {
        return {
			today_uv:0,
			is_show_data:true,
			minute:{
				xAxis:{		type:'category',data:[]},
				yAxis: {	type: 'value'	},
				series:[{	data: [],	type: 'line',label:{show:true}	}]
			},
			hour:{
				xAxis:{		type:'category',data:[]},
				yAxis: {	type: 'value'	},
				series:[{	data: [],	type: 'line',label:{show:true}	},
					{	data: [],	type: 'line',lineStyle: {	normal: {color: '#ccc',width: 2,type: 'line'}}	},
				]
			},
			this_week_uv:0,
			last_week_uv:0,
			last_week:{
				xAxis:{		type:'category',data:['周一','周二','周三','周四','周五','周六','周日']},
				yAxis: {	type: 'value'	},
				series:[{	data: [],	type: 'bar',label:{show:true}	}]
			},
			this_week:{
				xAxis:{		type:'category',data:['周一','周二','周三','周四','周五','周六','周日']},
				yAxis: {	type: 'value'	},
				series:[{	data: [],	type: 'bar',label:{show:true}	}]
			},
			this_month:{
				xAxis:{		type:'category',data:[]},
				yAxis: {	type: 'value'	},
				series:[{	data: [],	type: 'bar',label:{show:true}	}]
			}
        }
    },
    methods:{
		async	load_uv(){
			this.is_show_dialog = true;
			//1. 初始化今天的数据
			this.initialize_today_uv();
			//2.  初始化 每小时数据
			this.initialize_per_hour_uv();
			//3.  初始化 每5分钟的数据
			this.initialize_per_five_minutes_uv();
			//4. 初始化周的数据
			this.initialize_week_data();
			//5. 初始化月的数据
			// let {data:month_data}	= await axios.get(this.$httpUrl+
			// 		"tb-third-party-wang-small-routine-monitor-count/getPerDateInMonth/"+	this.small_routine_id);
			// let index = month_data.index ;
			// for(let i =0 ; i <month_data.array.length ; i++){
			// 	let date_index =( index - i + month_data.array.length-1 ) % (month_data.array.length) ;
			// 	if(date_index != 0){
			// 		this.this_month.xAxis.data.push(date_index);
			// 		this.this_month.series[0].data.push(month_data.array[date_index]);
			// 	}
			// }
			// console.log(this.this_month);
			//6. 表格赋值
			// var my_chart_month =  echarts.init(document.getElementById('echart_month'));
			// my_chart_month.setOption(this.this_month);
		},
		
		close(){
			this.$emit("close");
		},
		async	initialize_today_uv(){
			let {data:today_data} = await axios.get(this.$httpUrl+
					"tb-third-party-wang-small-routine-monitor-count/getTodayTotalUvById/"+	this.small_routine_id);
			this.today_uv = today_data;
		},
		async	initialize_per_hour_uv(){
			let {data:hour_data} = await axios.get(this.$httpUrl+
					"tb-third-party-wang-small-routine-monitor-count/getPerHourUV/"+	this.small_routine_id);
			for(let i= 0 ;i<24;i++){
				let j = (hour_data.index+i+1) % 24
				this.hour.xAxis.data.push(j+'~'+(j+1))
				this.hour.series[0].data.push(hour_data.array[j])
			}
			let {data:hour_yesterday_data} = await axios.get(this.$httpUrl+
					"tb-third-party-wang-small-routine-monitor-count/getPerHourForYesterdayUV/"+	this.small_routine_id);
			for(let i= 0;i<24;i++){
				let j = (hour_yesterday_data.index+i+1) % 24
				this.hour.series[1].data.push(hour_yesterday_data.array[j])
			}
			var my_chart_hour =  echarts.init(document.getElementById('echart_hour'))
			my_chart_hour.setOption(this.hour);
		},
		async	initialize_per_five_minutes_uv(){
			let {data:minute_data} = await axios.get(this.$httpUrl+
					"tb-third-party-wang-small-routine-monitor-count/getPerFiveMinutesUV/"+	this.small_routine_id);
			for(let i= 0 ;i<12;i++){
				let j = (minute_data.index+i+1) % 12
				this.minute.xAxis.data.push((j*5)+'~'+(j*5+5))
				this.minute.series[0].data.push(minute_data.array[j])
			}
			var my_chart_minute = echarts.init(document.getElementById('echart_minutes'))
			my_chart_minute.setOption(this.minute);
		},
		async	initialize_week_data(){
			let {data:week_data} = await axios.get(this.$httpUrl+
					"tb-third-party-wang-small-routine-monitor-count/getPerDateUV/"+	this.small_routine_id);
			let i = 0 ;
			this.last_week_uv = 0;
			this.this_week_uv = 0 ;
			for(;i<=week_data.index;i++){
				this.this_week.series[0].data[week_data.index-i]=week_data.array[i];
				this.this_week_uv += week_data.array[i];
			}
			for(let j=6;i<week_data.array.length;i++,j--){
				this.last_week.series[0].data[j]=week_data.array[i];
				this.last_week_uv += week_data.array[i];
			}
			var my_chart_this_week =  echarts.init(document.getElementById('echart_this_week'));
			my_chart_this_week.setOption(this.this_week);
			var my_chart_last_week =  echarts.init(document.getElementById('echart_last_week'));
			my_chart_last_week.setOption(this.last_week);
		}
    },
    computed:{
		
	},
    created(){
		this.load_uv();
    },
}
</script>

<style  lang="less" scoped>
.dialog{
	display:flex;		flex-direction: column;		align-items: center;
	width: 100%;
	.table{
		display: flex;
		width: 100%;
		.left{
			width: 70%;
		}
		.right{
			width: 30%;
		}
		.table_content{
			display: flex;	justify-content: space-evenly;	 flex-direction: column;	align-items: center;
		}
		
		.echart-minutes{
			width: 90%;
			height: 300px;
		}
	}
	.month_table{
		display: flex;	align-items: center;		justify-content: center;
		width: 100%;	height: 300px;
		.echart-month{
			width: 1200px;
			height: 300px;
		}
	}
	
}
</style>