<template>
    <div>
        <el-form-item label="第一排最上面的文字" prop="isShowFanGroup">
            <el-input  v-model="overturn.Title"  placeholder="请输入小程序的标题"  clearable></el-input>
        </el-form-item>
        <el-form-item label="标题左下角文字" prop="isShowFanGroup">
            <el-input  v-model="overturn.leftText"  placeholder="请输入小程序的标题"     clearable></el-input>
        </el-form-item>
        <el-form-item label="现金数字" prop="isShowFanGroup">   
            <el-input   mode="number"  v-model="overturn.cash"  placeholder="请输入小程序的标题"  clearable></el-input>
        </el-form-item>
    </div>
</template>

<script>
export default{
    components:{
        
    },
    props: {
        //是否展示粉丝群组件
        overturnJson:{
            type:String,
            default:"",
        },
    },
    data() {
        return {
            overturn:{}
        }
    },
    methods:{

    },
    computed:{
        
    },
    created(){
        this.overturn = JSON.parse(this.overturnJson);
    },
    watch:{
        overturn:{
            deep:true,
            handler:function(newValue){
                console.log("newValue",newValue);
                this.$emit("update:overturnJson",JSON.stringify(newValue) );
            }
        }
    }
}
</script>

<style  lang="less" scoped>
    .warn{
        color: red;
    }
</style>