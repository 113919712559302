<template>
  <div>
		<!-- 就是点击完左边导航栏，一进来的页面，上面是过滤器，下面是表格 -->
    <list
      ref="listRef"
      v-show="shoeType == 1"
	  :showSingleMes="showSingleMes"
      :showMessage="showMessage"
      :showExamine="showExamine"
      :showServiceProvider="showServiceProvider"
    />
    <!-- 内容管理 -->
    <template v-if="shoeType == 2">
      <!-- 高德 -->
      <messageApplet1
        v-if="
          showSingleMes.appletType == '1' || showSingleMes.appletType == '6'  
        "
        :showList="showList"
        :showSingleMes="showSingleMes"
      />
      <!-- 互动 -->
      <messageApplet2
        v-if="showSingleMes.appletType == '2'"
        :showList="showList"
        :showSingleMes="showSingleMes"
      />
      <!-- 电影 -->
      <messageApplet3
        v-if="showSingleMes.appletType == '7'"
        :showList="showList"
        :showSingleMes="showSingleMes"
      />
      <!-- 高德临时 -->
      <messageAppletgaoDe 
        :showList="showList"
        :showSingleMes="showSingleMes"
      />
    </template>
    <!-- 审核管理 -->
    <examine
      v-if="shoeType == 3"
      :showSingleMes="showSingleMes"
      :showList="showList"
      :refreshList="refreshList"
    />
    <!-- 服务商管理 -->
    <serviceProvider
      v-if="shoeType == 4"
      :showSingleMes="showSingleMes"
      :showList="showList"
    />
  </div>
</template>

<script>
import list from "./list.vue";
// import message from "./message.vue";
import messageApplet1 from "./applet1/index.vue";
import messageApplet2 from "./applet2/index.vue";
import messageApplet3 from "./applet3/index.vue";
import messageAppletgaoDe from "./gaoDeTemplate/index.vue";
import examine from "./examine.vue";
import serviceProvider from "./serviceProvider/index.vue";
// import { getAllType } from "@/services/thirdParty/thirdPartySmallRoutineType.js";
export default {
  name: "smallRoutine",
  components: {
    list,
    // message,
    messageApplet1,
    messageApplet2,
    messageApplet3,
    messageAppletgaoDe,
    examine,
    serviceProvider,
  },
  data() {
    return {
      // 展示内容
      shoeType: 1,
      showSingleMes: {},
      // smallRoutineTypes: [],
    };
  },
  created() {
    this.init();
  },
  watch: {
    "$route.query.type"() {
      this.shoeType = 1;
    },
  },
  methods: {
    init() {
      // this.getAllSmallRoutineType();
    },
    showList() {
      this.shoeType = 1;
    },
    showMessage(e) {
      this.showSingleMes = e;
      this.shoeType = 2;
    },
    showExamine(e) {
      this.showSingleMes = e;
      this.shoeType = 3;
    },
    showServiceProvider(e) {
      this.showSingleMes = e;
      this.shoeType = 4;
    },
    // 刷新表格
    refreshList() {
      let listRef = this.$refs.listRef;
      if (listRef != undefined && listRef != null) {
        listRef.getList();
      }
    },
    // // 获取所有小程序分类列表
    // async getAllSmallRoutineType() {
    //   let res = await getAllType();
    //   if (!res.success) {
    //     return this.$message.error(res.err);
    //   }
    //   this.smallRoutineTypes = res.data;
    // },
  },
};
</script>

<style lang="scss" scoped></style>
