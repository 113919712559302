<template>
    <div	class="block_container">
		<div	class="block">
			<!-- 删除照片按钮 -->
			<el-button		size="mini"	style="margin-left: 0;"	type="primary"		v-if="current_state == 'edit'"	:disabled="current==-1"	@click="delete_image">删除该照片</el-button>
			<!-- 添加照片按钮 -->
			<el-upload		class="upload-demo"		name="image"	:action="upload_image_action_path"	:headers="upload_image_head"	:on-success="success_upload"	:on-error="erorr_upload"		:show-file-list='false'>
				<el-button		size="mini"	style="margin-left: 0;"	type="primary"		v-if="current_state == 'edit'">添加照片</el-button>
			</el-upload>
			<!-- 选择照片按钮 -->
			<el-button		size="mini"	type="primary"		:disabled="current==-1"	v-if="current_state == 'choose'"	@click="submit">选择该图片</el-button>
			<!-- 状态选择 -->
			<el-button		size="mini"	style="margin-left: 0;"	type="primary"	@click="change_state">{{current_state == 'edit'?'返回选择':'编辑图片'}}</el-button>
		</div>
		<!-- 实际照片展示,一般展示多张图片 -->
		<div	class="block"	v-for="(item,index)	in pop_cdn_image_paths"	:key="index">
			<!-- 如果用户选择了这张图片，那么就会显示一个打勾 -->
			<img	class="correct"		v-if="current==index" src="./image/correct.svg"/>
			<img class="img"	:class="current==index?'img_choosed':'img_no_choosed'"	:src="item" 		@click="choose_image(index)"/>
		</div>
	</div>
</template>

<script>
	import axios from 'axios';
export default{
    components:{
        
    },
    props: {
        
    },
    data() {
        return {
            pop_cdn_image_paths:[],//cdn图片的路径
			current:-1,	//选择的图片的下标
			current_state:'choose',//如果是choose，那么就是选择图片，如果是编辑，那就是edit
			upload_image_head:{//token配置
				Authorization:sessionStorage.getItem("token")
			},
        }
    },
    methods:{
		//初始化数据
		async	initialize_data(){
			 //获取(弹窗的图片的) cdn图片路径
			this.pop_cdn_image_paths = (await axios.get(this.$httpUrl+"redpacket/advertiseImage/list")).data.message;
			// console.log(this.pop_cdn_image_paths)
		},
		//选中图片
		choose_image(index){
			this.current = index;
		},
		//提交图片
		submit(){
			this.$emit('submit',this.pop_cdn_image_paths[this.current] )
		},
		// 替换成cdn的
		submitCdn(){
			if(this.image_url){
				let urlObject = new URL(this.image_url);
				urlObject.hostname = "cdn.daliuliang.top";
				urlObject.protocol = "http:"; 
				this.$emit('update:image_url',urlObject.href );
			}else{
				alert("请选择图片");
			}
		},
		// 替换成普通吃带宽的
		submitBandwidth(){
			if(this.image_url){
				let urlObject = new URL(this.image_url);
				urlObject.hostname = "daliuliang.top";
				urlObject.protocol = "http:";
				this.$emit('update:image_url',urlObject.href );
			}else{
				alert("请选择图片");
			}
		},
		//改变状态，一种是eidt就是编辑图片，一种是choose就是选择照片
		change_state(){
			if(this.current_state == 'choose'){
				this.current_state = 'edit';
			}else{
				this.current_state = 'choose';
			}
		},
		//成功上传图片文件
		success_upload(){
			this.$message.success("成功上传文件");
			this.initialize_data();
		},
		//失败上传图片文件
		erorr_upload(res){
			this.$message.error("失败上传文件"+res);
		},
		//删除图片
		async	delete_image(){
			// console.log(this.pop_cdn_image_paths[this.current]);
			const result = (await axios({
				method:'GET',
				url:this.$httpUrl+"redpacket/advertiseImage/delete?webPath="+this.pop_cdn_image_paths[this.current],
			}))
			if(result.status==200&&result.data.code == 200){
				this.$message.success("删除成功");
			}else{
				this.$message.error("删除失败");
			}
			this.initialize_data();
		}
    },
    computed:{
		upload_image_action_path(){
			return this.$httpUrl + 'redpacket/advertiseImage/upload';
		},
	},
    created(){
        this.initialize_data();
    },
}
</script>

<style  lang="less" scoped>
.block_container{
	height: 150px;		width: 600px;
	display:flex;
	align-items: center;
	.block{
		height: 120px;	width: 120px; 	
		display: flex;	flex-direction: column;		align-items: center;		justify-content: space-evenly;
		position: relative;
		.img{
			height: 100px;	width: 100px;
			cursor: pointer;
		}
		.img_choosed{	//被选中
			border: 5px solid #71d5a1;
			border-radius: 10px;
			// filter: brightness(120%);
		}
		.img_no_choosed{	//没被选中
			border: 2px dashed #ccc;
			border-radius: 10px;
			background-color: #eee;
			filter: brightness(70%);
		}
		.correct{
			width: 60px;		height: 60px;
			filter: invert(67%) sepia(8%) saturate(3667%) hue-rotate(56deg) brightness(99%) contrast(81%);
			opacity: 0.95;
			z-index: 1000;
			position: absolute;
		}
	}
}

</style>