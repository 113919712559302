<template>
	<el-dialog		:visible="dialogFormVisible"		width="660px" 	@close="close"	@	title="选择区域"	>
		<el-button type="primary"	class="top_button" @click="checkAll">全 选</el-button>
		<el-button							class="top_button" @click="clearAll" >清 空</el-button>
<!-- 		<el-upload		action="http://localhost:8080/posts/"	:on-progress="handleChange"    :file-list="fileList"    class="el-upload" >
			<el-button	>由文件生成数据（在console内）</el-button>
		</el-upload> -->
		<div	class="container"	>
			<el-tree :data="region_code"	node-key="label"	ref="tree"	show-checkbox></el-tree>
		</div>
		<div slot="footer">
			<el-button @click="close">取 消</el-button>
			<el-button type="primary" @click="submit">确 定</el-button>
		</div>
	</el-dialog>
</template>

<script>
import { read, utils } from "xlsx";
import {region_code} from './chooseRegion/china_region_code.js';
export default{
    components:{
        
    },
    props: {
		dialogFormVisible:{
			type:Boolean,
			default:false,
		}
    },
    data() {
        return {
			fileList:[],
			tableHead:[],
            tableData:[],
			output:[],
			region_code:region_code
        }
    },
    methods:{
        // 导入excel文件,成功时执行，这里导入文件夹下的
        handleChange(res, file, fileList) {
              // 将文件放入
              for (let i = 0; i < fileList.length; i++) {
                if (file.name != fileList[i].name) {
                  this.fileList.push({
                    name: file.name,
                    url: "",
                    uid: file.uid
                  });
                }
              }
              const files = { 0: file };
              this.readExcel(files);
        },
		//从excel文件中导入数据到vue中
		readExcel(file) {
			const fileReader = new FileReader();
			fileReader.onload = ev => {
				try {
					const data = ev.target.result;
					const workbook = read(data, { type: "binary" });
					const params = [];
					// 取对应表生成json表格内容
					workbook.SheetNames.forEach(item => {
						this.tableData.push(utils.sheet_to_json(workbook.Sheets[item]));
					});
					// 该算法仅针对表头无合并的情况
					if (this.tableData.length > 0) {
						// 获取excel中第一个表格数据tableData[0][0]，并且将表头提取出来
						for (const key in this.tableData[0][0]) {
							this.tableHead.push(key);
						}
					}
				  // 重写数据
				} catch (e) {
					console.log("error:" + e);
					return false;
				}
			};
			fileReader.readAsBinaryString(file[0].raw);
			setTimeout(()=>{this.handle_tableData()},1000)
		},
		//把vue中的数据进行加工，使得数据可以被组件使用
		handle_tableData(){
			this.tableData = this.tableData[0];
			this.tableData.forEach((item,index)=>{
				//1.判断是否存在这个省
				let province_index = this.output.findIndex(item2=> item2['provinceCode'] == item['省编码']) ;
				if(province_index == -1){	//如果不存在那就创建
					province_index = this.output.push({label:item['省'],'provinceCode':item['省编码'] ,children:[],}) -1;
				}
				//2.判断该元素是否是个省数据，没有市的信息
				if(item['市']==""){
					return ;
				}
				//3.判断该省内是否存在这个市，没有就添加
				let city_index = this.output[province_index].children.findIndex(item2 => item2['cityCode'] == item['市编码'])
				if(city_index == -1){//如果不存在那就创建 
					this.output[province_index].children.push({label:item['市'],'provinceCode':item['省编码'] ,'cityCode':item['市编码']}) ;
				}
			})
			console.log(JSON.stringify(this.output));
		},
		//全选 el-tree 这个节点
		checkAll(){
			let keys = this.region_code.map(item=>item.label);
			 this.$refs.tree.setCheckedKeys(keys)
		},
		//清空所有 已选项
		clearAll(){
			this.$refs.tree.setCheckedKeys([]);
		},
		close(){
			this.$emit("dialogFormClose");
		},
		submit(){
			this.close();
			this.$message.success("成功选择了地区");
			let temp = this.$refs.tree.getCheckedNodes().map(item=>{
				if(item['cityCode'] == undefined){
					return {provinceCode:item['provinceCode'].toString()}
				}
				return {provinceCode:item['provinceCode'].toString(),cityCode:item['cityCode'].toString()}
			}).filter(item=>
				item['cityCode'] == undefined || 
				this.$refs.tree.getCheckedNodes().findIndex(item1 => item1['provinceCode'].toString() == item['provinceCode']&& item1['cityCode'] == undefined ) == -1
			)
			
			// let temp_string = temp
			console.log(temp);
			this.$emit("submit",temp);
		},
    },
    computed:{
			
	},
    created(){
        
    },
	mounted() {
		
	}
}
</script>

<style  lang="less" scoped>
.top_button{
	margin-bottom: 20px;
}
.container{
	width: 570px;
	height: 400px;
	overflow-y: scroll;
	
}
</style>