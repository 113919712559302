<template>
  <div class="div_main">
    <div style="margin: 20px"></div>
    <el-form
      class="elForm"
      ref="formRef"
      :model="form"
      label-width="auto"
      :rules="formRules"
    >
      <el-form-item label="小程序名称" prop="name">
        <el-input
          v-model="form.name"
          maxlength="50"
          show-word-limit
          placeholder="请输入小程序名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="小程序appId" prop="appId">
        <el-input
          v-model="form.appId"
          maxlength="50"
          show-word-limit
          placeholder="请输入小程序appId"
        ></el-input>
      </el-form-item>
			<el-form-item label="是否是第三方平台" prop="isThirdParty">
			  <el-switch v-model="form.isThirdParty"></el-switch>
			</el-form-item>
      <el-form-item label="授权token" prop="appAuthToken" v-show="form.isThirdParty">
        <el-input
          v-model="form.appAuthToken"
          maxlength="100"
          show-word-limit
          placeholder="请输入授权token"
        ></el-input>
      </el-form-item>
      <el-form-item label="aes密钥" prop="aesKey">
        <el-input
          v-model="form.aesKey"
          maxlength="100"
          show-word-limit
          placeholder="请输入aes密钥"
        ></el-input>
      </el-form-item>
      <el-form-item label="商家Id" prop="userId">
        <el-input
          v-model="form.userId"
          maxlength="100"
          show-word-limit
          placeholder="请输入商家Id"
        ></el-input>
      </el-form-item>
      <el-form-item label="模板" prop="templateId"  v-show="form.isThirdParty">
        <el-select v-model="form.templateId" placeholder="请选择小程序模板">
          <el-option
            v-for="item in templateList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
	  
		<el-form-item label="选择平台类型" prop="flatform">
			<el-select		v-model="form.flatform"	placeholder="请选择小程序模板">
	  			<el-option	 v-for="(item,index) in flatform_list"	:key="index"		:label="item.label"	:value="item.value"></el-option>
			</el-select>
		</el-form-item>
			<!-- 以下是小程序的 -->
			<el-form-item label="小程序公钥" prop="publicKey" v-show="!form.isThirdParty">
			  <el-input
			    v-model="form.publicKey"
			    placeholder="请输入小程序公钥"
			  ></el-input>
			</el-form-item>
			<el-form-item label="小程序私钥" prop="privateKey" v-show="!form.isThirdParty">
			  <el-input
			    v-model="form.privateKey"
			    placeholder="请输入小程序私钥"
			  ></el-input>
			</el-form-item>
			<el-form-item label="支付宝公钥" prop="alipayPublicKey" v-show="!form.isThirdParty">
			  <el-input
			    v-model="form.alipayPublicKey"
			    placeholder="请输入支付宝公钥"
			  ></el-input>
			</el-form-item>
      <el-form-item label="小程序模板" prop="appletType" v-show="!form.isThirdParty">
			  <el-select v-model="form.appletType" placeholder="请选择小程序模板">
			    <el-option  v-for="item in appletTypeList"  :key="item.id"  :label="item.name"  :value="item.code"  ></el-option>
			  </el-select>
			</el-form-item>
			<!-- 小程序的结束 -->
      <!-- 备注 -->
      <el-form-item label="备注" prop="remark">
        <el-input
          v-model="form.remark"
          placeholder="请输入备注"
          clearable
          maxlength="150"
          show-word-limit
          type="textarea"
          :rows="2"
        ></el-input>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-switch v-model="form.status"></el-switch>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('formRef')">
          新建
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { smallroutineAdd } from "@/services/thirdParty/smallRoutine/add";
import {	getAllSmallRoutineTypeList }	from '@/services/thirdParty/smallRoutine/smallRoutine.js'
export default {
  props: ["refreshData", "templateList", "type",'appletType'],
  name: "applet1Add",
  data() {
    return {
		form: {
			name: "",
			appId: "",
			appAuthToken: "",
			templateId: "",
			publicKey:"",
			privateKey:"",
			alipayPublicKey:"",
			status: true,	
			isThirdParty:true,
			appletType:this.appletType,
			flatform:"alipay",
		},
      // 表格提交验证
      formRules: {
        name: [ { required: true, message: "请输入小程序名称", trigger: "blur" },],
        appId: [{ required: true, message: "请输入appId", trigger: "blur" }],
        appAuthToken: [  { required: true, message: "请输入授权token", trigger: "blur" }, ],
        templateId: [  { required: true, message: "请选择关联的模板", trigger: "change" },  ],
        publicKey: [  { required: false, message: "请输入小程序公钥", trigger: "blur" },  ],
        privateKey: [  { required: false, message: "请输入小程序私钥", trigger: "blur" },  ],
        alipayPublicKey: [  { required: false, message: "请输入支付宝公钥", trigger: "blur" }, ],
        appletType: [  { required: false, message: "请输入小程序类型", trigger: "blur" }, ],
      },
			appletTypeList:[],
		flatform_list:[
			{label:'支付宝',value:'alipay'},
			{label:'华为',value:'huawei'},
			{label:'抖音',value:'douyin'}
		]
    };
  },
  created() {
			this.getSmallRoutineTypes();
	},
  methods: {
		//获取小程序的类别,这个是按倒非第三方小程序，创建非第三方小程序需要小程序的类别
		async	getSmallRoutineTypes(){
				//正在加载...   的遮罩层
				const loading = this.$loading({  lock: true,  text: "Loading",  spinner: "el-icon-loading",  background: "rgba(0, 0, 0, 0.7)",	});
				const result = await getAllSmallRoutineTypeList();
				loading.close();
				console.log(result);
				if(result.success){
						this.appletTypeList = result.data ;
				}else{
					this.$message.error("请求小程序类别失败")
				}
		},
    // 表单提交
    submitForm(formName) {
      // 表单提交信息校验
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitFormMes();
        } else {
          return false;
        }
      });
    },
    async submitFormMes() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await smallroutineAdd(this.form);
      loading.close();
      if (!res.success) {
        this.$message.error(res.err);
        return;
      }
      this.$message.success("操作成功");
      this.resetForm("formRef");
      // 刷新页面信息
      this.refreshData();
    },
    // 清空表单
    resetForm(formName) {
      // 清除form表单内容
      this.$refs[formName].resetFields();
    },
    isNull(e) {
      return e == null || e == undefined || e == "";
    },
  },
	watch: {
		//设置控制第三方的表单必填事项  比如在第三方里面token和模板是必须要填的 不是第三方 小程序公钥私钥 阿里公钥必须要填
		'form.isThirdParty':{
				handler(newvalue){
					if(newvalue){	//如果是第三方
						this.formRules.appAuthToken[0].required = true ;
						this.formRules.templateId[0].required = true ;
						this.formRules.publicKey[0].required = false ;
						this.formRules.privateKey[0].required = false ;
						this.formRules.alipayPublicKey[0].required = false ;
						this.formRules.appletType[0].required = false ;
					}else{
						this.formRules.appAuthToken[0].required = false ;
						this.formRules.templateId[0].required = false ;
						this.formRules.publicKey[0].required = true ;
						this.formRules.privateKey[0].required = true ;
						this.formRules.alipayPublicKey[0].required = true ;
						this.formRules.appletType[0].required = true ;
					}
				}
		}
	},
};
</script>

<style lang="less" scoped></style>
