<template>
    <div class="main_container">
		<table_template_main :column_name="columnName" class_name="tb-third-party-wang-lucard-menu"	:base_url="httpUrl"></table_template_main>
    </div>
</template>

<script>
	import axios from 'axios';
	import table_template_main from '@/components/template/wang/table_template_main_new'
export default{
    components:{
        table_template_main
    },
    props: {
        
    },
    data() {
        return {
			columnName:[	//每一列的数据
				{label:"标题",prop:"title",map_type:'none',width:120},
				{label:"跳转类型",prop:"jumpType",map_type:'base', map:{ h5:'h5','calculator':'计算器'},width:100},
				{label:"h5路径",prop:"h5Url",map_type:'none',width:250},
				{label:"排序",prop:"sort",map_type:'none',width:100},
				{label:"跳转计算器",prop:"calculatorId",map_type:'base',map:{}},	
				{label:"图片",prop:"image",map_type:'image',},	
			],
			httpUrl:this.$httpUrl
        }
    },
    methods:{
		async	initialize_calculator(){
			const calculator_list_result =	await	 axios.get(this.httpUrl+"smallRoutineService/getAll");
			console.log(calculator_list_result.data.message)
			calculator_list_result.data.message.forEach(item=>{
				this.columnName[4].map[item.code] = item.name
			})
		}
    },
    computed:{
		
	},
    created(){
		this.initialize_calculator();
    },
}
</script>

<style  lang="less" scoped>
.main_container{
		height: 900rpx;
}
</style>